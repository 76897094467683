<template>
  <div class="prozess-wrapper">
    <div class="prozess">
      <div>
        <div v-if="!claimed">
          <TaskBlocker :assignee="data.assignee" />
        </div>
        <div v-if="!isLoading">
          <CustomerDetails
            v-if="customerAddress !== null"
            :data="customerAddress"
            parseSalutation
            title="Kundeninformationen"
          />

          <DisplayContainer v-if="contractId" :label="'Vertragsinformationen'">
            <DisplaySubContainer label="Allgemein">
              <div class="input-row">
                <DataDisplay label="Vertragsnummer">
                  <div>
                    {{ contractId }}
                  </div>
                </DataDisplay>
                <DataDisplay label="Anschaltdatum">
                  <div v-if="provisioningDate">
                    {{
                      new Date(provisioningDate).toLocaleDateString('de-De', {
                        weekday: 'long',
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit'
                      })
                    }}
                  </div>
                </DataDisplay>
              </div>
            </DisplaySubContainer>
            <DisplaySubContainer label="Internet">
              <div class="input-row">
                <DataDisplay label="Hauptprodukt">
                  <div v-if="mainInternetProductName">
                    {{ mainInternetProductName }}
                  </div>
                </DataDisplay>
                <DataDisplay label="Optionen">
                  <div
                    v-for="productName in additionalInternetProductNames"
                    :key="productName"
                  >
                    {{ productName }}
                  </div>
                </DataDisplay>
              </div>
            </DisplaySubContainer>
            <DisplaySubContainer label="Telefonie">
              <div class="input-row">
                <DataDisplay label="Hauptproduct">
                  <div v-if="mainVoiceProductName">
                    {{ mainVoiceProductName }}
                  </div>
                </DataDisplay>
                <DataDisplay label="Telefonnummern">
                  <div v-if="phoneNumbers">
                    <div v-for="phone in phoneNumbers" :key="phone">
                      {{ phone }}
                    </div>
                  </div>
                </DataDisplay>
              </div>
            </DisplaySubContainer>

            <DisplaySubContainer label="Geräte" :lastSubComponent="true">
              <HardwareDeviceProductWithDevice
                productType="ONT"
                :productWithDevice="ontProductWithDevice"
              />
              <HardwareDeviceProductWithDevice
                productType="CPE"
                :productWithDevice="routerProductWithDevice"
              />
              <div class="input-row">
                <div></div>
                <DataDisplay label="MSAN">
                  <div v-if="gponHierarchy">
                    {{ gponHierarchy.gponHierarchy }}
                  </div>
                </DataDisplay>
              </div>
            </DisplaySubContainer>
          </DisplayContainer>
          <DisplayContainer label="Notizen">
            <v-textarea
              ref="memo"
              v-model="memo"
              class="input-field"
              auto-grow
              clearable
              clear-icon="mdi-delete"
              counter="60"
              :rules="[
                (v) =>
                  inputIsValid(v) ||
                  'Die Notiz darf maximal 60 Zeichen lang sein!'
              ]"
              name="memo-input"
              placeholder=""
              :value="memo"
              :disabled="!claimed"
              @input="validateInputs()"
            />
          </DisplayContainer>
        </div>

        <div v-else>
          <Loader />
        </div>
      </div>

      <div v-if="!isLoading" class="buttons-wrapper">
        <v-btn
          color="secondary"
          large
          :disabled="buttonsDisabled || !claimed"
          @click="saveTask()"
        >
          Speichern
        </v-btn>
        <v-btn
          color="primary"
          large
          :disabled="buttonsDisabled || !claimed"
          @click="completeTask()"
        >
          Aufgabe abschließen
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import CustomerDetails from '@/components/CustomerDetails.vue';
import Loader from '@/components/elements/Loader.vue';
import TaskBlocker from '../TaskBlocker.vue';
import NotificationObject from '@/main/NotificationObject.js';
import { DateTimeFormatter, LocalDateTime } from '@js-joda/core';

import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import DataDisplay from '@/components/elements/DataDisplay.vue';
import { HTTP } from '@/main/httpClient.js';
import DisplaySubContainer from '@/components/elements/DisplaySubContainer.vue';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';
import HardwareDeviceProductWithDevice from '@/components/deviceProvisioning/HardwareDeviceProductWithDevice.vue';

export default {
  components: {
    CustomerDetails,
    Loader,
    TaskBlocker,
    DisplayContainer,
    DataDisplay,
    DisplaySubContainer,
    HardwareDeviceProductWithDevice
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      isLoading: false,
      validationErrors: false,
      customerData: null,
      customerAddress: null,
      contractId: null,
      customerId: null,
      provisioningDate: null, // TODO: Remove this value
      memo: null,
      mainPhoneProductName: null,
      mainInternetProductName: null,
      mainVoiceProductName: null,
      additionalInternetProductNames: [],
      ontProductWithDevice: null,
      routerProductWithDevice: null,
      uniPort: null,
      gponHierarchy: null,
      phoneNumbers: null
    };
  },
  computed: {
    username() {
      return this.$store.getters.getUsername;
    },
    claimed() {
      return this.data.assignee === this.username;
    },
    buttonsDisabled() {
      return this.isLoading || !this.inputIsValid(this.memo);
    }
  },
  mounted: function () {
    this.loadTaskData(this.data.id);
  },
  methods: {
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    validateInputs() {
      let note = false;
      if (this.memo) {
        note = false;
      } else {
        note = true;
      }
      this.validationErrors = note;
    },
    loadTaskData: function (id) {
      this.isLoading = true;
      HTTP.get(`/task/${id}/variables`)
        .then((resp) => {
          const formatter = DateTimeFormatter.ofPattern(
            "yyyy-MM-dd'T'HH:mm:ss.SSSZ"
          );
          // Prefer local customer data to process customer data
          this.customerData = resp.data.customer.value;
          this.customerId = resp.data.customerId.value;
          this.contractId = resp.data.contractId.value;
          this.provisioningDate = LocalDateTime.parse(
            resp.data.provisioningDate.value,
            formatter
          );

          if (resp.data.memo !== undefined) {
            this.memo = resp.data.memo.value;
          }

          return resp;
        })
        .then(() => {
          return Promise.allSettled([
            this.fetchAddressInfo(this.contractId),
            this.fetchContractInfo(this.contractId),
            this.retrieveInternetMainProduct(this.contractId),
            this.retrieveInternetOptionProducts(this.contractId),
            this.retrieveVoiceMainProduct(this.contractId),
            this.retrieveONTProductWithDevice(this.contractId),
            this.retrieveRouterProductWithDevice(this.contractId),
            this.retrieveUniPort(this.contractId)
          ]);
        })
        .catch((e) => {
          console.log('e', e);
          this.showMessage(
            'error',
            'Die Kundendaten konnten nicht geladen werden!'
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    fetchAddressInfo(contractId) {
      return HTTP.get(`/customer/contactAddress/${contractId}`).then((res) => {
        this.customerAddress = { ...res.data, customerId: this.customerId };
      });
    },
    fetchContractInfo(contractId) {
      return HTTP.get(`/customer/${contractId}`).then((res) => {
        // get phone numbers
        this.phoneNumbers = res.data.phoneNumbers;
      });
    },
    retrieveInternetMainProduct(contractId) {
      return this.retrieveAvailableProducts(contractId, 'INTERNET_MAIN').then(
        (response) => {
          if (response.data.length > 0) {
            this.mainInternetProductName = response.data[0].name;
          } else {
            throw new Error('Internethauptprodukt wurde nicht gefunden.');
          }
        }
      );
    },
    retrieveInternetOptionProducts(contractId) {
      return this.retrieveAvailableProducts(contractId, 'INTERNET_OPTION').then(
        (response) => {
          this.additionalInternetProductNames = response.data.map(
            (product) => product.name
          );
        }
      );
    },
    retrieveVoiceMainProduct(contractId) {
      return this.retrieveAvailableProducts(contractId, 'VOICE_MAIN').then(
        (response) => {
          if (response.data.length > 0) {
            this.mainVoiceProductName = response.data[0].name;
          } else {
            throw new Error('Telefoniehauptprodukt wurde nicht gefunden.');
          }
        }
      );
    },
    retrieveAvailableProducts(contractId, productType) {
      return HTTP.get(`/products/contract/availableProducts/${contractId}`, {
        params: { productType: productType }
      });
    },
    retrieveRouterProductWithDevice(contractId) {
      return this.retrieveCpeProductWithDeviceOfContract(
        contractId,
        'ROUTER'
      ).then((response) => (this.routerProductWithDevice = response.data));
    },
    retrieveONTProductWithDevice(contractId) {
      return this.retrieveCpeProductWithDeviceOfContract(
        contractId,
        'ONT'
      ).then((response) => (this.ontProductWithDevice = response.data));
    },
    retrieveCpeProductWithDeviceOfContract(contractId, cpeDeviceCategory) {
      return HTTP.get(`/products/contract/cpe/${contractId}`, {
        params: { cpeDeviceCategory: cpeDeviceCategory }
      });
    },
    retrieveUniPort(contractId) {
      return HTTP.get('/components/uniPorts', {
        params: { contractId: contractId }
      })
        .then((response) => {
          this.uniPort = response.data;
          this.retrieveGponHierarchy(this.uniPort.serviceDataId);
        })
        .catch(() => {});
    },
    retrieveGponHierarchy(uniPortId) {
      return HTTP.get('/components/uniPorts/gponHierarchy', {
        params: { uniPortId: uniPortId }
      })
        .then((response) => {
          this.gponHierarchy = response.data;
        })
        .catch(() => {});
    },
    saveTask: function () {
      HTTP.post(`/task/${this.data.id}/localVariables`, {
        modifications: {
          memo: {
            value: this.memo,
            type: 'String'
          }
        }
      });
    },
    completeTask: function () {
      HTTP.post(
        `/provisioningTask/checkCustomerPhoneNumberRegistration/${this.data.id}`,
        this.memo,
        {
          headers: {
            'Content-Type': 'text/plain'
          }
        }
      )
        .then((resp) => {
          const message = 'Der Abschluss der Aufgabe war erfolgreich!.';
          this.showMessage('info', message);
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Der Abschluss der Aufgabe war nicht erfolgreich!'
            )
          )
        );
    },
    inputIsValid: function (memoInput) {
      return memoInput == null || memoInput.length <= 60;
    }
  }
};
</script>

<style scoped>
.buttons-wrapper {
  display: grid;
  grid-template-columns: auto max-content;
  grid-gap: var(--goe-spacing-1);
}

.buttons-wrapper .v-btn {
  width: max-content;
  margin-left: auto;
  margin-right: var(--goe-spacing-0);
}

.input-field {
  background: var(--goe-background-highlight);
}

option:first-child {
  display: none;
}
</style>
