<template>
  <div class="prozess-wrapper">
    <div class="prozess">
      <div>
        <div v-if="!claimed">
          <TaskBlocker :assignee="data.assignee" />
        </div>
        <div v-if="!isLoading">
          <div v-if="contractId">
            <DisplayContainer label="Adressabgleich">
              <div class="input-row">
                <DataDisplay label="Vertragsnummer">
                  <div v-if="contractId">
                    {{ contractId }}
                  </div>
                </DataDisplay>
                <DataDisplay label="Kundennummer">
                  <div v-if="customerId">
                    {{ customerId }}
                  </div>
                </DataDisplay>
              </div>
              <DisplayContainer
                label="Installationsadresse"
                :isSubcomponent="true"
              >
                <div class="input-row">
                  <NameDisplay :addressDto="installationAddress" />
                  <AddressDisplay :addressDto="installationAddress" />
                </div>
              </DisplayContainer>
              <DisplayContainer label="OCS-Adresse" :isSubcomponent="true">
                <div
                  v-if="
                    ocsAccountAddress !== undefined && ocsAccountAddress != null
                  "
                  class="input-row"
                >
                  <NameDisplay :addressDto="ocsAccountAddress" />
                  <AddressDisplay :addressDto="ocsAccountAddress" />
                </div>
                <div v-else>
                  <v-icon
                    class="ocs-address-missing-icon"
                    small
                    color="red"
                  >
                    mdi-alert-circle-outline
                  </v-icon>
                  <span>
                    {{ ocsAccountMissingAddressLabel }}
                  </span>
                </div>
              </DisplayContainer>
            </DisplayContainer>
            <DisplayContainer :label="'Anmerkungen zum Adressabgleich'">
              <v-textarea
                ref="memo"
                v-model="memo"
                class="input-field"
                auto-grow
                clearable
                clear-icon="mdi-delete"
                name="memo-input"
                placeholder="Anmerkungen zum Adressabgleich"
                :value="memo"
                :disabled="!claimed"
              />
            </DisplayContainer>
          </div>
        </div>
        <div v-else>
          <Loader />
        </div>
      </div>
      <div v-if="!isLoading" class="buttons-wrapper">
        <v-btn
          color="secondary"
          large
          :disabled="buttonsDisabled || !inputIsValid(memo)"
          @click="saveTask()"
        >
          Speichern
        </v-btn>
        <v-btn
          color="primary"
          large
          :disabled="buttonsDisabled"
          @click="completeTask()"
        >
          Installationsadresse und OCM-Adresse abgeglichen
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationObject from '@/main/NotificationObject.js';
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import CustomerDetails from '@/components/CustomerDetails.vue';
import Loader from '@/components/elements/Loader.vue';
import AllOrderedProductsTable from '@/components/AllOrderedProductsTable.vue';
import TextLink from '@/components/elements/TextLink.vue';
import TaskBlocker from '../TaskBlocker.vue';
import { HTTP } from '@/main/httpClient.js';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';
import DataDisplay from '../../components/elements/DataDisplay.vue';
import AddressDisplay from '../../components/dataDisplays/AddressDisplay.vue';
import NameDisplay from '../../components/dataDisplays/NameDisplay.vue';

export default {
  components: {
    TaskBlocker,
    Loader,
    CustomerDetails,
    NotificationObject,
    DisplayContainer,
    AllOrderedProductsTable,
    TextLink,
    ErrorMessageBuilder,
    DataDisplay,
    AddressDisplay,
    NameDisplay
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      ocsAccountMissingAddressLabel: '',
      isLoading: false,
      installationAddress: null,
      contractId: null,
      customerId: null,
      memo: null,
      ocsAccountAddress: null
    };
  },
  computed: {
    username() {
      return this.$store.getters.getUsername;
    },
    claimed() {
      return this.data.assignee === this.username;
    },
    buttonsDisabled() {
      return this.isLoading || !this.claimed;
    }
  },
  mounted: function () {
    this.loadTaskData(this.data.id);
  },
  methods: {
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    loadTaskData: function (id) {
      this.isLoading = true;
      HTTP.get(`/task/${id}/variables`)
        .then((resp) => {
          // Prefer local customer data to process customer data
          this.customerId = resp.data.customerId.value;
          this.contractId = resp.data.contractId.value;
          if (resp.data.ocsAccountAddress !== undefined) {
            this.ocsAccountAddress = resp.data.ocsAccountAddress.value;
          } else {
            this.ocsAccountMissingAddressLabel = `Keine Adresse zum OCS Account mit der OCS ID ${resp.data.ocsAccountId.value} gefunden`;
          }
          if (resp.data.memo !== undefined) {
            this.memo = resp.data.memo.value;
          }

          return resp;
        })
        .then((response) => {
          return this.fetchInstallationAddress(this.contractId);
        })
        .catch((e) => {
          console.log('e', e);
          this.showMessage(
            'error',
            'Die Kundendaten konnten nicht geladen werden!'
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    fetchInstallationAddress(contractId) {
      return HTTP.get(`/customer/addressByType/${contractId}/INSTALLATION`)
        .then((res) => {
          this.installationAddress = res.data;
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Installationsadresse konnte nicht geladen werden!'
            )
          )
        );
    },
    saveTask: function () {
      HTTP.post(`/task/${this.data.id}/localVariables`, {
        modifications: {
          memo: {
            value: this.memo,
            type: 'String'
          }
        }
      })
        .then((resp) => {
          const message = 'Das Abspeichern des Memos war erfolgreich!.';
          this.showMessage('info', message);
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Das Abspeichern des Memos war nicht erfolgreich!'
            )
          )
        );
    },
    completeTask: function () {
      HTTP.post(
        `/portingTask/closeAdjustOCSAddress/${this.data.id}`,
        {},
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
        .then((resp) => {
          const message = 'Der Abschluss der Aufgabe war erfolgreich!.';
          this.showMessage('info', message);
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Ein Fehler ist aufgetreten'
            )
          )
        );
    },
    inputIsValid: function (memoInput) {
      return memoInput == null || memoInput.length <= 5000;
    }
  }
};
</script>

<style scoped>
.buttons-wrapper .v-btn {
  width: max-content;
  margin-left: auto;
  margin-right: var(--goe-spacing-1);
  float: right;
}

.loader {
  margin: auto;
}

.group > div:nth-child(2) > div {
  margin-bottom: var(--goe-spacing-2);
}

.group > div:nth-child(2) > div:last-child {
  margin-bottom: var(--goe-spacing-0);
}

.input-wrapper {
  margin-bottom: var(--goe-spacing-2);
}
.ocs-address-missing-icon {
  vertical-align: baseline;
}
</style>
