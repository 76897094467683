<template>
  <div>
    <div class="input-row">
      <DataDisplay label="Vertragsnummer">
        <div>
          {{ contractId }}
        </div>
      </DataDisplay>
      <DataDisplay label="Anschaltdatum">
        <div v-if="provisioningDate">
          {{
            new Date(provisioningDate).toLocaleDateString('de-De', {
              weekday: 'long',
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            })
          }}
        </div>
      </DataDisplay>

      <DataDisplay label="Telefonie">
        <div v-if="provisionPhoneNumbers">
          <v-icon color="green">
            mdi-check
          </v-icon>

          <span v-show="provisionPhoneNumbers">
            mit {{ numberRequestPhoneNumbers }} Rufnummern
          </span>
        </div>
        <v-icon v-else color="red">
          mdi-window-close
        </v-icon>
      </DataDisplay>
      <DataDisplay label="Internet">
        <v-icon v-if="provisionInternet" color="green">
          mdi-check
        </v-icon>
        <v-icon v-else color="red">
          mdi-window-close
        </v-icon>
      </DataDisplay>
    </div>
  </div>
</template>

<script>
import DataDisplay from '@/components/elements/DataDisplay.vue';

export default {
  components: {
    DataDisplay
  },
  props: {
    contractId: {
      type: Number,
      required: true
    },
    provisioningDate: {
      type: String,
      required: true
    },
    provisionInternet: { type: Boolean, required: true },
    provisionPhoneNumbers: {
      type: Boolean,
      required: true
    },

    numberRequestPhoneNumbers: {
      type: Number,
      required: true
    },
    ontSerialNumber: {
      type: String,
      required: false,
      default: null
    }
  }
};
</script>

<style scoped></style>
