<template>
  <div class="prozess-wrapper">
    <div class="prozess">
      <div>
        <div v-if="!claimed">
          <TaskBlocker :assignee="data.assignee" />
        </div>

        <div v-if="!taskVariablesLoading">
          <CustomerDetails
            v-if="customerData !== null"
            :data="customerData"
            title="Kundeninformationen"
          />

          <PortingInformation
            v-if="portingData !== null"
            :wbciProcessId="currentWbciProcessId"
            :currentProvider="currentCarrier"
            :portingData="portingData"
          />
          <div class="number-group">
            <div>Rufnummern</div>
            <div class="input-row">
              <div>
                <div class="label">
                  Nicht automatisch portierbar
                </div>
                <div
                  v-if="
                    portingData.phoneNumbersNotFromEmittingCarrier != null &&
                      portingData.phoneNumbersNotFromEmittingCarrier != []
                  "
                >
                  <div
                    v-for="number in portingData.phoneNumbersNotFromEmittingCarrier"
                    :key="`${number.areaCode}${number.numberPart}`"
                  >
                    <div v-if="number.carrier.routingId != null">
                      {{
                        `+${number.countryCode} ${number.areaCode} ${number.numberPart} (${number.carrier.routingId})`
                      }}
                    </div>
                    <div v-else>
                      {{
                        `+${number.countryCode} ${number.areaCode} ${number.numberPart} (Unbekannter EKP)`
                      }}
                    </div>
                  </div>
                </div>
                <div v-else>
                  -
                </div>
              </div>
              <div>
                <div class="label">
                  Automatisch portierbar
                </div>
                <div
                  v-if="
                    portingData.phoneNumbersFromWbciProcess != null &&
                      portingData.phoneNumbersFromWbciProcess != []
                  "
                >
                  <div
                    v-for="number in portingData.phoneNumbersFromWbciProcess"
                    :key="`${number.areaCode}${number.numberPart}`"
                  >
                    {{
                      `+${number.countryCode} ${number.areaCode} ${number.numberPart}`
                    }}
                  </div>
                </div>
                <div v-else>
                  -
                </div>
              </div>
            </div>
          </div>

          <div class="group">
            <div>Notizen</div>
            <div>
              <v-textarea
                v-model="memo"
                auto-grow
                clearable
                clear-icon="mdi-delete"
                counter="70"
                :rules="[
                  (v) =>
                    v == null ||
                    v.length <= 70 ||
                    'Die Notiz darf maximal 70 Zeichen lang sein!'
                ]"
                name="memo-input"
                placeholder="Notiz eingeben..."
                :value="memo"
                :disabled="!claimed"
              />
            </div>
          </div>
        </div>

        <div v-else>
          <Loader />
        </div>
      </div>

      <div v-if="!taskVariablesLoading" class="buttons-wrapper">
        <v-btn
          :disabled="completeButtonDisabled || !claimed"
          color="secondary"
          large
          @click="saveTask()"
        >
          Speichern
        </v-btn>
        <v-btn
          color="primary"
          large
          :disabled="completeButtonDisabled || !claimed"
          @click="completeTask()"
        >
          Aufgabe abschließen
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from '@/main/httpClient.js';
import NotificationObject from '@/main/NotificationObject.js';

import CustomerDetails from '@/components/CustomerDetails.vue';
import NormalButton from '@/components/elements/NormalButton.vue';
import Loader from '@/components/elements/Loader.vue';
import TaskBlocker from '../TaskBlocker.vue';
import GoetelInput from '@/components/elements/GoetelInput.vue';
import PortingInformation from './PortingInformation.vue';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';

export default {
  name: 'ActionPRocessUnknownEkp',
  components: {
    NormalButton,
    TaskBlocker,
    CustomerDetails,
    Loader,
    GoetelInput,
    PortingInformation
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    taskVariablesLoading: true,
    portingData: null,
    customerData: null,
    memo: null,
    requiredDataErrors: [],
    requiredDataVisited: [],
    currentWbciProcessId: null,
    currentCarrier: null
  }),
  computed: {
    username() {
      return this.$store.getters.getUsername;
    },
    completeButtonDisabled() {
      return (
        this.taskVariablesLoading ||
        this.requiredDataErrors.length > 0 ||
        (this.memo != null && this.memo.length > 70)
      );
    },
    currentDate() {
      const today = new Date();
      return (
        today.getFullYear() +
        '-' +
        String(today.getMonth() + 1).padStart(2, '0') +
        '-' +
        String(today.getDate()).padStart(2, '0')
      );
    },
    claimed() {
      return this.data.assignee === this.username;
    }
  },
  mounted: function () {
    this.loadTaskData(this.data.id);
  },
  methods: {
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    loadTaskData: function (id) {
      this.taskVariablesLoading = true;
      HTTP.get(`/task/${id}/variables`)
        .then((resp) => {
          this.portingData = resp.data.porting.value;
          this.currentCarrier = resp.data.currentProvider.value;
          this.contractId = this.portingData.contractId;
          this.currentWbciProcessId = resp.data.currentWbciProcessId.value;
          this.customerData = resp.data.customer.value;
          if (resp.data.memo !== undefined) {
            this.memo = resp.data.memo.value;
          }
        })
        .catch(() => {
          this.showMessage(
            'error',
            'Die Task-Daten konnten nicht geladen werden!'
          );
        })
        .finally(() => {
          this.taskVariablesLoading = false;
        });
    },
    saveTask: function () {
      HTTP.post(`/task/${this.data.id}/localVariables`, {
        modifications: {
          memo: {
            value: this.memo,
            type: 'String'
          }
        }
      });
    },
    completeTask: function () {
      HTTP.post(`/portingTask/deviatingCarrier/${this.data.id}`, this.memo, {
        headers: {
          'Content-Type': 'text/plain'
        }
      })
        .then((resp) => {
          this.showMessage(
            'info',
            'Die Aufgabe wurde erfolgreich abgeschlossen.'
          );
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Der Abschluss der Aufgabe war nicht erfolgreich!'
            )
          )
        );
    }
  }
};
</script>

<style scoped>
.buttons-wrapper {
  display: grid;
  grid-template-columns: auto max-content;
  grid-gap: var(--goe-spacing-1);
}

.buttons-wrapper .v-btn {
  width: max-content;
  margin-left: auto;
  margin-right: var(--goe-spacing-0);
}

.loader {
  margin: auto;
}

.description {
  font-size: var(--goe-fontSize-small);
  margin-top: var(--goe-spacing-0_5);
}

.number-group {
  margin-bottom: var(--goe-spacing-2);
}

.number-group > div:nth-child(1) {
  color: var(--goe-fontColor-highlight);
}

.number-group > div:not(:first-child) {
  padding: var(--goe-spacing-2);
  background: var(--goe-background-highlight);
  font-size: var(--goe-fontSize-small);
  margin-bottom: var(--goe-spacing-1);
}

.number-group > div:not(:first-child) > div {
  margin-bottom: var(--goe-spacing-2);
}

.number-group > div:not(:first-child) > div:last-child {
  margin-bottom: var(--goe-spacing-0);
}

.group > div:nth-child(2) > div {
  margin-bottom: var(--goe-spacing-2);
}

.group > div:nth-child(2) > div:last-child {
  margin-bottom: var(--goe-spacing-0);
}

.label {
  width: calc(100% - 4px);
}

.v-textarea {
  max-width: calc(100% - 12px);
}
</style>
