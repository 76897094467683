<template>
  <div>
    <porting-data
      :state="currentState"
      :isLoading="loading"
      :dataDisplay="!claimed"
      @stateChanged="handleState($event)"
      @inputValidationChanged="handleValidation($event)"
    />
  </div>
</template>

<script>
import { HTTP } from '@/main/httpClient.js';
import NotificationObject from '@/main/NotificationObject.js';
import PortingData from '../../processLaunch/porting/PortingDataCheck.vue';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';
export default {
  components: {
    PortingData
  },
  props: {
    claimed: {
      type: Boolean,
      required: true,
      default: false
    },
    taskContractId: {
      type: Number,
      required: true,
      default: null
    }
  },

  data: () => ({
    currentState: {
      contractId: null,
      contactInformation: null,
      portingData: {
        portAllNumbers: false,
        portingDate: null,
        phoneNumbers: [],
        selectedCarrier: null,
        withCancellation: true
      }
    },
    contractDataLoading: false,
    currentInputsValid: true
  }),
  computed: {
    contractId() {
      return this.taskContractId;
    },
    loading() {
      return this.contractDataLoading;
    }
  },
  created() {
    this.retrieveContractInfo();
  },
  methods: {
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    handleValidation(value) {
      this.currentInputsValid = value;
      this.$emit('taskDataValid', value);
    },
    handleState(value) {
      this.currentState = value;
      this.$emit('humanTaskData', value);
    },
    // fetch Infos
    retrieveContractInfo: function () {
      this.contractDataLoading = true;
      this.currentState.contractId = this.contractId;
      HTTP.get(`/porting/contractInfo/${this.contractId}`)
        .then((result) => {
          this.transformAddressData(result.data);
          this.currentState.portingData.phoneNumbers =
            result.data.phoneNumbers.map((numberString) => ({
              number: numberString,
              active: false
            }));
          this.currentState.portingData.selectedCarrier =
            result.data.carrierDto;
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Der Abschluss der Aufgabe war nicht erfolgreich!'
            )
          )
        )
        .finally(() => {
          this.contractDataLoading = false;
        });
    },
    transformAddressData: function (contractInfo) {
      this.currentState.contactInformation = {
        customerId: contractInfo.customerId,
        firstName: contractInfo.contactInformation.firstName,
        lastName: contractInfo.contactInformation.lastName,
        salutation: contractInfo.contactInformation.salutation,
        birthDate: contractInfo.contactInformation.birthDate,
        street: contractInfo.contactInformation.street,
        houseNumber: contractInfo.contactInformation.houseNumber,
        houseNumberSupplement:
          contractInfo.contactInformation.houseNumberSupplement,
        city: contractInfo.contactInformation.city,
        zipCode: contractInfo.contactInformation.zipCode,
        phone: contractInfo.contactInformation.phone,
        mobile: contractInfo.contactInformation.cellPhoneNumber,
        email: contractInfo.contactInformation.email
      };
    }
  }
};
</script>

<style></style>
