var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"prozess-wrapper"},[_c('div',{staticClass:"prozess"},[_c('div',[(!_vm.claimed)?_c('div',[_c('TaskBlocker',{attrs:{"assignee":_vm.data.assignee}})],1):_vm._e(),(!_vm.taskDataLoading)?_c('div',[_c('DisplayContainer',{directives:[{name:"show",rawName:"v-show",value:(_vm.msanError),expression:"msanError"}],attrs:{"label":''}},[_c('span',{staticStyle:{"color":"red"}},[_c('v-icon',[_vm._v("mdi-alert-outline")]),_vm._v(" "+_vm._s(_vm.msanError)+" ")],1)]),(_vm.msanConfig && _vm.msanConfig.msan_port)?_c('DisplayContainer',{attrs:{"label":"Neue MSAN Konfigurationen"}},[(_vm.msanConfig && _vm.msanConfig.msan_port)?_c('MSANConfiguration',{attrs:{"data":_vm.msanConfig,"dataIsLoading":_vm.taskDataLoading}}):_vm._e()],1):_vm._e(),(_vm.customerData)?_c('CustomerDetails',{attrs:{"customerId":_vm.customerId,"data":_vm.customerData,"parseSalutation":true,"small":"","title":"Kundeninformationen"}}):_vm._e(),(_vm.provisioningData)?_c('DisplayContainer',{attrs:{"label":'Vertragsinformationen'}},[(_vm.provisioningData)?_c('ContractData',{attrs:{"contractId":_vm.provisioningData.contractId,"numberRequestPhoneNumbers":_vm.provisioningData.numberRequestPhoneNumbers,"provisionInternet":_vm.provisioningData.provisionInternet,"provisionPhoneNumbers":_vm.provisioningData.phoneNumbersForProvisioning.length > 0 ||
                _vm.provisioningData.numberRequestPhoneNumbers > 0,"provisioningDate":_vm.provisioningData.provisioningDate}}):_vm._e()],1):_vm._e(),_c('DisplayContainer',{attrs:{"label":"Produkte"}},[_c('AllOrderedProductsTable',{attrs:{"allProducts":_vm.allProducts}})],1),_c('DisplayContainer',{attrs:{"label":"Geräte"}},[_c('DisplayContainer',{attrs:{"label":"ONT Konfigurationen","isSubcomponent":true}},[(!_vm.taskDataLoading)?_c('HardwareDeviceSelection',{staticClass:"margin-top-1",attrs:{"disabled":!_vm.claimed || _vm.presentONTDeviceData !== undefined,"presentDeviceData":_vm.presentONTDeviceData,"deviceType":_vm.presentONTDeviceData
                  ? _vm.presentONTDeviceData.deviceType
                  : undefined},on:{"selectedDeviceChanged":function($event){{
                  _vm.selectedONTDevice = $event;
                  _vm.updateUpdatedMsanConfiguration();
                }},"inputIsValidChanged":function($event){_vm.selectedONTDeviceValid = $event}}}):_vm._e()],1),_c('DisplayContainer',{attrs:{"label":"GPON Konfigurationen","isSubcomponent":true}},[_c('UniPortSelection',{attrs:{"disabled":!_vm.claimed || _vm.updatedMsanConfigurationLoading,"presentUniPortid":_vm.availableUniPortId},on:{"selectedUniPortChanged":function($event){{
                  _vm.selectedUniPort = $event;
                  _vm.updateUpdatedMsanConfiguration();
                }},"inputIsValidChanged":function($event){_vm.selectedUniPortValid = $event}}})],1)],1),_c('DisplayContainer',{attrs:{"label":"Neue MSAN Konfigurationen"}},[_c('MSANConfiguration',{attrs:{"data":_vm.updatedMsanConfiguration,"dataIsLoading":_vm.updatedMsanConfigurationLoading}})],1)],1):_c('div',{staticClass:"loader"},[_c('loader')],1)]),(!_vm.taskDataLoading)?_c('div',{staticClass:"buttons-wrapper"},[_c('v-btn',{attrs:{"disabled":!_vm.claimed,"color":"secondary","large":""},on:{"click":function($event){_vm.skipDialog = !_vm.skipDialog}}},[_vm._v(" Provisionierung überspringen ")]),_c('v-btn',{attrs:{"disabled":!_vm.claimed || !_vm.selectedONTDevice || !_vm.selectedUniPortValid,"color":"primary","large":""},on:{"click":function($event){return _vm.completeTask()}}},[_vm._v(" Provisionieren ")])],1):_vm._e(),_c('v-dialog',{attrs:{"width":"20vw"},model:{value:(_vm.skipDialog),callback:function ($$v) {_vm.skipDialog=$$v},expression:"skipDialog"}},[_c('v-card',[_c('br'),_c('v-card-text',[_c('p',[_vm._v(" Sind Sie sicher, dass keine MSAN Provisionierung durchgeführt werden soll? ")]),_c('v-spacer'),_c('div',{staticClass:"dialog-buttons"},[_c('v-btn',{staticClass:"align-center",attrs:{"small":"","color":"secondary"},on:{"click":function($event){_vm.skipDialog = !_vm.skipDialog}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{staticClass:"align-center",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.skipProvisioningAndCompleteTask(true)}}},[_vm._v(" Ja ")])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }