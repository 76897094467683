<template>
  <div class="prozess-wrapper">
    <div class="prozess">
      <div>
        <div v-if="!claimed">
          <TaskBlocker :assignee="data.assignee" />
        </div>
        <div v-if="!isLoading">
          <div class="group">
            <div>Abbruchmeldung</div>
            <div>
              <div v-for="abbmReason in abbmReasons" :key="abbmReason">
                {{ abbmReason }}
              </div>
            </div>
          </div>
          <CustomerDataEdit
            v-if="customerData !== null"
            :data="customerData"
            title="Kundendaten"
            :disabled="!claimed"
            @onDataChange="customerData = $event"
            @invalid="customerDataInvalid = $event"
          />
          <div class="group">
            <div>Portierungsdaten</div>
            <div>
              <div class="input-row">
                <div>
                  <div class="label">
                    Vertragsnummer
                  </div>
                  <div>
                    {{ portingData.contractId }}
                  </div>
                </div>
                <div>
                  <div class="label">
                    WBCI-Nummer
                  </div>
                  <div class="input-row-max-content-left">
                    <div>
                      <TextLink linkType="ocs" :linkObject="currentWbciId">
                        {{ currentWbciId !== null ? currentWbciId : '-' }}
                      </TextLink>
                    </div>
                  </div>
                </div>
              </div>
              <div class="input-row">
                <v-radio-group
                  v-model="wbciWithCancellation"
                  label="WBCI-Prozess-Typ"
                  :disabled="!claimed"
                >
                  <v-radio
                    label="Rufnummerportierung mit Kündigung (KUE-MRN)"
                    :value="true"
                  />
                  <v-radio
                    label="Reine Rufnummernportierung (RRNP)"
                    :value="false"
                  />
                </v-radio-group>
              </div>
              <div class="input-row">
                <v-radio-group
                  v-model="portAllNumbers"
                  label="Alle Rufnummern portieren?"
                  :disabled="!claimed"
                >
                  <v-radio label="Alle Rufnummern" :value="true" />
                  <v-radio label="Nur angegebene Rufnummern" :value="false" />
                </v-radio-group>
              </div>
              <NumberManagement
                :numberList="portingData.phoneNumbers"
                :contractId="portingData.contractId"
                label="Zu portierende Rufnummern"
                :disabled="!claimed"
                :invalid="!numberListValid"
                @numberAdded="addNumber($event)"
                @numberRemoved="removeNumber($event)"
              />
            </div>
          </div>
          <CarrierSelection
            :value="selectedCarrier"
            :disabled="!claimed"
            label="Aktueller Anbieter"
            :errorMessage="
              selectedCarrier === null
                ? 'Es muss ein Carrier angegeben werden!'
                : null
            "
            @input="selectedCarrier = $event"
          />
          <div class="group">
            <div>Notizen</div>
            <div>
              <v-textarea
                v-model="memo"
                auto-grow
                clearable
                clear-icon="mdi-delete"
                counter="70"
                :rules="[
                  (v) => v == null || v.length <= 70 || 'Die Notiz darf maximal 70 Zeichen lang sein!',
                  (v) => !/[^\u0000-\u00ff]/g.test(v) || 'Unerlaubte Zeichen vorhanden: ' + v.replace(/[\u0000-\u00ff]/g, '')
                ]"
                name="memo-input"
                placeholder="Notiz eingeben..."
                :disabled="!claimed"
              />
            </div>
          </div>
          <div class="group">
            <div>Portierungsergebnis</div>
            <div>
              <div class="input-row">
                <v-select
                  v-model="selectedResult"
                  label="Ergebnis *"
                  :items="selectValues"
                  :value="portingResult.AUTOMATIC_RETRY"
                  placeholder="Ergebnis auswählen..."
                  persistent-placeholder
                  :disabled="!claimed"
                  :error-messages="resultSelectionErrors"
                />
              </div>
              <div
                v-if="selectedResult == portingResult.SUCCESSFUL"
                class="input-row"
              >
                <DatePicker
                  v-model="portingDate"
                  disableWeekend
                  disableHolidays
                  :minDate="minPortingDate"
                  label="Portierungsdatum *"
                  :errorMessage="datePickerErrorMessage"
                  :disabled="!claimed"
                />
              </div>
              <div
                v-else-if="
                  selectedResult ==
                    portingResult.UNSUCCESSFUL_REPLACEMENT_NEEDED
                "
                class="input-row"
              >
                <DatePicker
                  v-model="portingDate"
                  disableWeekend
                  disableHolidays
                  :minDate="minPortingDate"
                  label="Schaltdatum *"
                  :errorMessage="datePickerErrorMessage"
                  :disabled="!claimed"
                />
                <v-text-field
                  ref="numberAmount"
                  v-model="numberAmount"
                  label="Anzahl benötigter Rufnummern"
                  persistent-placeholder
                  required
                  type="number"
                  :rules="[
                    () =>
                      !!numberAmount ||
                      'Es muss eine Anzahl von benötigten Rufnummern angegeben werden',
                    () =>
                      (!!numberAmount && /^([1-9]|10)$/.test(numberAmount)) ||
                      'Die Anzahl benötigter Rufnummern muss zwischen 1 und 10 liegen.'
                  ]"
                  :disabled="!claimed"
                  @input="validateInputs()"
                />
              </div>
              <div
                v-else-if="selectedResult == portingResult.AUTOMATIC_RETRY"
                class="input-row"
              >
                <DatePicker
                  v-model="portingDate"
                  disableWeekend
                  disableHolidays
                  :minDate="minPortingDate"
                  label="Gewünschtes Portierungsdatum *"
                  :errorMessage="datePickerErrorMessage"
                  :disabled="!claimed"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <Loader />
        </div>
      </div>
      <div v-if="!isLoading" class="buttons-wrapper">
        <v-btn
          :disabled="isLoading || !claimed"
          color="secondary"
          large
          @click="saveTask()"
        >
          Speichern
        </v-btn>
        <v-btn
          v-if="selectedResult === portingResult.AUTOMATIC_RETRY"
          :disabled="isLoading || !claimed || completeButtonDisabled"
          color="secondary"
          large
          @click="retryPorting()"
        >
          Automatisch portieren
        </v-btn>
        <v-btn
          v-else
          :disabled="isLoading || !claimed || completeButtonDisabled"
          color="primary"
          large
          @click="completeTask()"
        >
          Aufgabe abschließen
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationObject from '@/main/NotificationObject.js';

import { HTTP } from '@/main/httpClient.js';

import CarrierSelection from '@/components/CarrierSelectionVuetify.vue';
import Loader from '@/components/elements/Loader.vue';
import TaskBlocker from '../TaskBlocker.vue';
import CustomerDataEdit from '@/components/CustomerDataEdit.vue';
import NumberManagement from './NumberManagement.vue';
import DatePicker from '@/components/elements/DatePicker.vue';
import TextLink from '@/components/elements/TextLink.vue';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';

import { LocalDate, DayOfWeek } from '@js-joda/core';

export default {
  name: 'ActionHandlePortingAbort',

  components: {
    CarrierSelection,
    CustomerDataEdit,
    TaskBlocker,
    NumberManagement,
    Loader,
    DatePicker,
    TextLink
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    taskDataLoading: true,
    portingData: null,
    selectedCarrier: null,
    customerData: null,
    currentWbciId: null,
    memo: null,
    numberAmount: '1',
    portingDate: null,
    selectedResult: null,
    validationErrors: false,
    customerDataInvalid: false,
    currentProvider: null,
    wbciWithCancellation: false,
    portAllNumbers: false,
    wbciInitError: null
  }),
  computed: {
    username() {
      return this.$store.getters.getUsername;
    },
    claimed() {
      return this.data.assignee === this.username;
    },
    isLoading() {
      return this.taskDataLoading;
    },
    numberListValid() {
      return this.portingData.phoneNumbers.length > 0;
    },
    completeButtonDisabled() {
      return (
        this.validationErrors ||
        !this.claimed ||
        (this.selectedResult === this.portingResult.SUCCESSFUL &&
          (this.portingDate === null || !this.numberListValid)) ||
        (this.selectedResult === this.portingResult.AUTOMATIC_RETRY &&
          ((!!this.selectedCarrier && !this.selectedCarrier.contractExists) ||
            !this.portingDate)) ||
        !!this.datePickerErrorMessage ||
        !this.inputsValid
      );
    },
    resultSelectionErrors() {
      if (
        !!this.selectedCarrier &&
        !this.selectedCarrier.contractExists &&
        this.selectedResult === this.portingResult.AUTOMATIC_RETRY
      ) {
        return 'Mit dem ausgewählten Carrier kann keine automatische Portierung durchgeführt werden!';
      } else {
        return null;
      }
    },
    currentDate() {
      const today = new Date();
      return (
        today.getFullYear() +
        '-' +
        String(today.getMonth() + 1).padStart(2, '0') +
        '-' +
        String(today.getDate()).padStart(2, '0')
      );
    },
    minPortingDate() {
      let minDate = LocalDate.now();
      if (this.selectedResult === this.portingResult.AUTOMATIC_RETRY) {
        if (this.wbciWithCancellation) {
          minDate = minDate.plusDays(30);
        }
      } else if (
        this.selectedResult ===
        this.portingResult.UNSUCCESSFUL_REPLACEMENT_NEEDED
      ) {
        minDate = minDate.plusDays(5);
      }
      const dayOfWeek = minDate.dayOfWeek();
      if (dayOfWeek === DayOfWeek.SATURDAY) {
        minDate = minDate.plusDays(2);
      } else if (dayOfWeek === DayOfWeek.SUNDAY) {
        minDate = minDate.plusDays(1);
      }
      return minDate.toString();
    },
    datePickerErrorMessage() {
      if (!this.portingDate) {
        if (
          this.selectedResult === this.portingResult.SUCCESSFUL ||
          this.selectedResult === this.portingResult.AUTOMATIC_RETRY
        ) {
          return 'Es muss ein Portierungsdatum angegeben werden!';
        } else if (
          this.selectedResult ===
          this.portingResult.UNSUCCESSFUL_REPLACEMENT_NEEDED
        ) {
          return 'Es muss ein Schalttermin angegeben werden!';
        }
      }
      if (new Date(this.portingDate) < new Date(this.minPortingDate)) {
        if (
          this.selectedResult === this.portingResult.SUCCESSFUL ||
          this.selectedResult === this.portingResult.AUTOMATIC_RETRY
        ) {
          return 'Das gegebene Portierungsdatum liegt vor dem minimalen Portierungsdatum!';
        } else if (
          this.selectedResult ===
          this.portingResult.UNSUCCESSFUL_REPLACEMENT_NEEDED
        ) {
          return 'Der gegebene Schalttermin liegt vor dem minimalen Schalttermin!';
        }
      }
      return null;
    },
    abbmReasons() {
      let reasons = [];
      if (this.currentProvider.contractExists) {
        if (
          Object.keys(this.portingData.processRuns).includes(this.currentWbciId)
        ) {
          reasons = reasons.concat(
            this.portingData.processRuns[this.currentWbciId].abbmReasons
          );
        }
      } else {
        reasons.push('Der aktuelle Anbieter ist kein WBCI-Vertragspartner.');
      }
      if (this.wbciInitError !== null) {
        reasons.push(this.wbciInitError);
      }
      return reasons;
    },
    inputsValid() {
      return (
        !this.customerDataInvalid &&
        this.selectedCarrier !== null &&
        this.numberListValid &&
        this.datePickerErrorMessage === null
      );
    }
  },
  mounted: function () {
    this.loadTaskData(this.data.id);
  },
  created() {
    this.portingResult = {
      AUTOMATIC_RETRY: 'AUTOMATIC_RETRY',
      SUCCESSFUL: 'SUCCESSFUL',
      UNSUCCESSFUL: 'UNSUCCESSFUL',
      UNSUCCESSFUL_REPLACEMENT_NEEDED: 'UNSUCCESSFUL_REPLACEMENT_NEEDED'
    };
    this.selectValues = [
      {
        text: 'Automatische Portierung erneut versuchen',
        value: this.portingResult.AUTOMATIC_RETRY
      },
      {
        text: 'Portierung erfolgreich',
        value: this.portingResult.SUCCESSFUL
      },
      {
        text: 'Portierung nicht erfolgreich',
        value: this.portingResult.UNSUCCESSFUL
      },
      {
        text: 'Portierung nicht erfolgreich, Ersatzrufnummern benötigt',
        value: this.portingResult.UNSUCCESSFUL_REPLACEMENT_NEEDED
      }
    ];
  },
  methods: {
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    validateInputs() {
      let numberAmount = false;
      if (this.$refs && this.$refs.numberAmount) {
        numberAmount = !this.$refs.numberAmount.validate(true);
      } else {
        numberAmount = false;
      }
      this.validationErrors = numberAmount;
    },
    loadTaskData: function (id) {
      this.taskDataLoading = true;
      HTTP.get(`/task/${id}/variables`)
        .then((resp) => {
          // Prefer local customer data to process customer data
          if (resp.data.customerData !== undefined) {
            this.customerData = resp.data.customerData.value;
          } else {
            this.customerData = resp.data.customer.value;
          }
          this.portingData = resp.data.porting.value;
          this.currentProvider = resp.data.currentProvider.value;
          if (resp.data.selectedCarrier !== undefined) {
            this.selectedCarrier = resp.data.selectedCarrier.value;
          } else {
            this.selectedCarrier = this.currentProvider;
          }
          if (
            resp.data.currentWbciProcessId !== undefined &&
            resp.data.currentWbciProcessId !== null
          ) {
            this.currentWbciId = resp.data.currentWbciProcessId.value;
          }
          if (resp.data.memo !== undefined) {
            this.memo = resp.data.memo.value;
          }
          if (resp.data.portingDateGUI !== undefined) {
            this.portingDate = resp.data.portingDateGUI.value;
          } else {
            this.portingDate = this.portingData.requestedPortingDate;
          }
          if (resp.data.numberAmount !== undefined) {
            this.numberAmount = resp.data.numberAmount.value;
          } else {
            this.numberAmount = this.portingData.phoneNumbers.length;
          }
          if (resp.data.selectedResult !== undefined) {
            this.selectedResult =
              this.portingResult[resp.data.selectedResult.value];
          } else {
            this.selectedResult = this.portingResult.AUTOMATIC_RETRY;
          }
          if (resp.data.wbciWithCancellation !== undefined) {
            this.wbciWithCancellation = resp.data.wbciWithCancellation.value;
          } else {
            this.wbciWithCancellation =
              this.portingData.portPhoneNumbersWithCancellation;
          }
          if (resp.data.portAllNumbers !== undefined) {
            this.portAllNumbers = resp.data.portAllNumbers.value;
          } else {
            this.portAllNumbers = this.portingData.portAllPhoneNumbers;
          }
          if (resp.data.wbciInitError !== undefined) {
            this.wbciInitError = resp.data.wbciInitError.value;
          }
        })
        .catch((e) => {
          this.showMessage(
            'error',
            'Die Kundendaten konnten nicht geladen werden!'
          );
        })
        .finally(() => {
          this.taskDataLoading = false;
        });
    },
    addNumber: function (number) {
      this.portingData.phoneNumbers.push(number);
      this.saveNumberList();
    },
    removeNumber: function (number) {
      const numberIndex = this.portingData.phoneNumbers.indexOf(number);
      if (numberIndex !== -1) {
        this.portingData.phoneNumbers.splice(numberIndex, 1);
      }
      this.saveNumberList();
    },
    saveNumberList: function () {
      HTTP.put(`/task/${this.data.id}/variables/porting`, {
        value: JSON.stringify(this.portingData),
        type: 'Object',
        valueInfo: {
          objectTypeName: 'de.goetel.spot.porting.entity.PortingDto',
          serializationDataFormat: 'application/json'
        }
      })
        .then((resp) => {})
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Rufnummernliste konnte nicht gespeichert werden.'
            )
          )
        );
    },
    retryPorting: function () {
      const portingRetry = {
        automaticRetry: true,
        taskId: this.data.id,
        carrierDto: this.selectedCarrier,
        customerDto: this.customerData,
        memo: this.memo,
        portingWithCancellation: this.wbciWithCancellation,
        portAllNumbers: this.portAllNumbers,
        requestedPortingDate: this.portingDate
      };

      HTTP.post('/portingTask/retry', portingRetry)
        .then((resp) => {
          this.showMessage('success', 'Portierung erneut gestartet.');
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Portierung konnte nicht erneut gestartet werden.'
            )
          )
        );
    },
    saveTask: function () {
      HTTP.post(`/task/${this.data.id}/localVariables`, {
        modifications: {
          memo: {
            value: this.memo,
            type: 'String'
          },
          selectedResult: {
            value: this.selectedResult,
            type: 'String'
          },
          numberAmount: {
            value: this.numberAmount,
            type: 'String'
          },
          portingDateGUI: {
            value: this.portingDate,
            type: 'String'
          },
          wbciWithCancellation: {
            value: this.wbciWithCancellation,
            type: 'Boolean'
          },
          portAllNumbers: {
            value: this.portAllNumbers,
            type: 'Boolean'
          },
          selectedCarrier: {
            value: JSON.stringify(this.selectedCarrier),
            type: 'Object',
            valueInfo: {
              objectTypeName: 'de.goetel.spot.porting.entity.CarrierDto',
              serializationDataFormat: 'application/json'
            }
          },
          customerData: {
            value: JSON.stringify(this.customerData),
            type: 'Object',
            valueInfo: {
              objectTypeName: 'de.goetel.spot.porting.entity.CustomerDto',
              serializationDataFormat: 'application/json'
            }
          }
        }
      });
    },
    completeTask: function () {
      HTTP.post(`/portingTask/manualPortingResult/${this.data.id}`, {
        portingResult: this.selectedResult,
        portingDate: this.portingDate,
        phoneNumberAmount: this.numberAmount,
        memo: this.memo,
        selectedCarrier: this.selectedCarrier
      })
        .then((resp) => {
          this.showMessage(
            'info',
            `Die Portierung wurde mit dem Ergebnis "${this.selectedResult}" abgeschlossen.`
          );
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Der Abschluss der Aufgabe war nicht erfolgreich!'
            )
          )
        );
    }
  }
};
</script>

<style scoped>
.buttons-wrapper {
  display: grid;
  grid-template-columns: auto max-content;
  grid-gap: var(--goe-spacing-1);
}

.buttons-wrapper .v-btn {
  width: max-content;
  margin-left: auto;
  margin-right: var(--goe-spacing-0);
}

.loader {
  margin: auto;
}

.group > div:nth-child(2) > div {
  margin-bottom: var(--goe-spacing-2);
}

.group > div:nth-child(2) > div:last-child {
  margin-bottom: var(--goe-spacing-0);
}

.input-wrapper {
  margin-bottom: var(--goe-spacing-2);
}
</style>
