<template>
  <div class="prozess-wrapper">
    <div class="prozess">
      <div>
        <div v-if="!claimed">
          <TaskBlocker :assignee="data.assignee" />
        </div>
        <div v-if="!taskDataLoading">
          <DisplayContainer v-show="msanError" :label="''">
            <span style="color: red">
              <v-icon>mdi-alert-outline</v-icon> {{ msanError }}
            </span>
          </DisplayContainer>
          <DisplayContainer
            v-if="msanConfig && msanConfig.msan_port"
            label="Neue MSAN Konfigurationen"
          >
            <MSANConfiguration
              v-if="msanConfig && msanConfig.msan_port"
              :data="msanConfig"
              :dataIsLoading="taskDataLoading"
            />
          </DisplayContainer>
          <CustomerDetails
            v-if="customerData"
            :customerId="customerId"
            :data="customerData"
            :parseSalutation="true"
            small
            title="Kundeninformationen"
          />

          <!-- contract Data Section -->
          <DisplayContainer
            v-if="provisioningData"
            :label="'Vertragsinformationen'"
          >
            <ContractData
              v-if="provisioningData"
              :contractId="provisioningData.contractId"
              :numberRequestPhoneNumbers="
                provisioningData.numberRequestPhoneNumbers
              "
              :provisionInternet="provisioningData.provisionInternet"
              :provisionPhoneNumbers="
                provisioningData.phoneNumbersForProvisioning.length > 0 ||
                  provisioningData.numberRequestPhoneNumbers > 0
              "
              :provisioningDate="provisioningData.provisioningDate"
            />
          </DisplayContainer>

          <!--data product section -->
          <DisplayContainer label="Produkte">
            <AllOrderedProductsTable :allProducts="allProducts" />
          </DisplayContainer>
          <!-- serialNumber section -->
          <DisplayContainer label="Geräte">
            <!-- ONT selection -->
            <DisplayContainer
              label="ONT Konfigurationen"
              :isSubcomponent="true"
            >
              <HardwareDeviceSelection
                v-if="!taskDataLoading"
                class="margin-top-1"
                :disabled="!claimed || presentONTDeviceData !== undefined"
                :presentDeviceData="presentONTDeviceData"
                :deviceType="
                  presentONTDeviceData
                    ? presentONTDeviceData.deviceType
                    : undefined
                "
                @selectedDeviceChanged="
                  {
                    selectedONTDevice = $event;
                    updateUpdatedMsanConfiguration();
                  }
                "
                @inputIsValidChanged="selectedONTDeviceValid = $event"
              />
            </DisplayContainer>
            <!-- GPON selection -->
            <DisplayContainer
              label="GPON Konfigurationen"
              :isSubcomponent="true"
            >
              <UniPortSelection
                :disabled="!claimed || updatedMsanConfigurationLoading"
                :presentUniPortid="availableUniPortId"
                @selectedUniPortChanged="
                  {
                    selectedUniPort = $event;
                    updateUpdatedMsanConfiguration();
                  }
                "
                @inputIsValidChanged="selectedUniPortValid = $event"
              />
            </DisplayContainer>
          </DisplayContainer>
          <DisplayContainer label="Neue MSAN Konfigurationen">
            <MSANConfiguration
              :data="updatedMsanConfiguration"
              :dataIsLoading="updatedMsanConfigurationLoading"
            />
          </DisplayContainer>
        </div>
        <div v-else class="loader">
          <loader />
        </div>
      </div>
      <div v-if="!taskDataLoading" class="buttons-wrapper">
        <v-btn
          :disabled="!claimed"
          color="secondary"
          large
          @click="skipDialog = !skipDialog"
        >
          Provisionierung überspringen
        </v-btn>
        <v-btn
          :disabled="!claimed || !selectedONTDevice || !selectedUniPortValid"
          color="primary"
          large
          @click="completeTask()"
        >
          Provisionieren
        </v-btn>
      </div>
      <v-dialog v-model="skipDialog" width="20vw">
        <v-card>
          <br />
          <v-card-text>
            <p>
              Sind Sie sicher, dass keine MSAN Provisionierung durchgeführt
              werden soll?
            </p>
            <v-spacer></v-spacer>
            <div class="dialog-buttons">
              <v-btn
                small
                color="secondary"
                class="align-center"
                @click="skipDialog = !skipDialog"
              >
                Abbrechen
              </v-btn>
              <v-btn
                small
                color="primary"
                class="align-center"
                @click="skipProvisioningAndCompleteTask(true)"
              >
                Ja
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { HTTP } from '@/main/httpClient.js';
import NotificationObject from '@/main/NotificationObject.js';

import ContactInformation from '@/components/ContactInformation.vue';

import CustomerDetails from '@/components/CustomerDetails.vue';
import Loader from '@/components/elements/Loader.vue';
import TaskBlocker from '../TaskBlocker.vue';
import ElementWrapper from '@/components/elements/ElementWrapper.vue';
import Textfield from '@/components/elements/GoetelInputVuetify.vue';
import DisplayContainer from '@/components/elements/DisplayContainer.vue';

import ContractData from './ActionMSANProvisioningContractData.vue';
import HardwareDeviceSelection from '@/components/deviceProvisioning/HardwareDeviceSelection.vue';
import MSANConfiguration from '@/components/deviceProvisioning/MSANConfiguration.vue';
import UniPortSelection from '@/components/deviceProvisioning/UniPortSelection.vue';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';
import AllOrderedProductsTable from '@/components/AllOrderedProductsTable.vue';

export default {
  name: 'ActionMSANProvisioning',
  components: {
    TaskBlocker,
    CustomerDetails,
    ContactInformation,
    Loader,
    ElementWrapper,
    DisplayContainer,
    Textfield,
    MSANConfiguration,
    HardwareDeviceSelection,
    ContractData,
    AllOrderedProductsTable,
    UniPortSelection
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    skipDialog: false,
    taskDataLoading: true,
    customerData: null,
    serialNumber: null,
    contractId: null,
    portingData: null,
    provisioningData: null,
    customerId: null,
    products: null,
    allProducts: null,
    presentONTDeviceData: null,
    selectedONTDeviceValid: false,
    selectedONTDevice: null,
    availableUniPortId: undefined,
    selectedUniPort: null,
    selectedUniPortValid: false,
    updatedMsanConfiguration: null,
    updatedMsanConfigurationLoading: false
  }),
  computed: {
    userGroups() {
      return this.$auth.user().userRoles;
    },
    username() {
      return this.$store.getters.getUsername;
    },
    claimed() {
      return this.data.assignee === this.username;
    }
  },
  created: function () {
    this.loadUserData(this.data.id);
  },
  methods: {
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    loadUserData: function (id) {
      this.taskDataLoading = true;
      HTTP.get(`/task/${id}/variables`)
        .then((response) => {
          this.msanError = response.data.msanProvisioningError.value;
          this.msanConfig = response.data.msanConfig.value;
          this.provisioningData = response.data.provisioning.value;
          this.contractId = response.data.contractId.value;
          return response;
        })
        .then(() => {
          return Promise.allSettled([
            this.fetchContractInfo(this.contractId),
            this.loadProducts(this.contractId),
            this.loadONTInformation(this.contractId),
            this.fetchContractUniPort(this.contractId)
          ]);
        })
        .then((responses) => {
          if (responses.some((response) => response.status === 'rejected')) {
            this.showMessage(
              'error',
              'Die Kundendaten konnten nicht vollständig geladen werden!'
            );
          }
        })
        .catch((e) => {
          this.showMessage(
            'error',
            'Die Prozessdaten konnten nicht geladen werden!'
          );
        })
        .finally(() => (this.taskDataLoading = false));
    },
    loadProducts: function (contractId) {
      HTTP.get(`/products/contract/all/${contractId}`).then((resp) => {
        this.allProducts = resp.data;
      });
    },
    loadONTInformation: function (contractId) {
      return HTTP.get(`/products/contract/cpe/${contractId}`, {
        params: {
          cpeDeviceCategory: 'ONT'
        }
      }).then((res) => {
        const ontDevice = res.data.device;
        if (ontDevice) {
          this.presentONTDeviceData = {
            serviceDataId: ontDevice.serviceDataId,
            serialNumber: ontDevice.serialNumber,
            deviceType: ontDevice.cpeDeviceType
          };
        } else {
          this.presentONTDeviceData = undefined;
        }
      });
    },
    fetchContractInfo(contractId) {
      return HTTP.get(`/customer/${contractId}`).then((res) => {
        this.customerId = res.data.customerId;
        this.customerData = res.data.contactInformation;
      });
    },
    fetchContractUniPort(contractId) {
      return HTTP.get('/components/uniPorts', {
        params: { contractId: contractId }
      })
        .then((res) => {
          this.availableUniPortId = res.data.serviceDataId;
        })
        .catch(() => {});
    },
    updateUpdatedMsanConfiguration() {
      if (
        this.contractId != null &&
        this.selectedONTDevice != null &&
        this.selectedUniPort != null
      ) {
        this.fetchMsan(
          this.contractId,
          this.selectedUniPort.serviceDataId,
          this.selectedONTDevice.serviceDataId
        );
      } else {
        this.updatedMsanConfiguration = null;
      }
    },
    fetchMsan(contractId, uniPortId, ontDeviceId) {
      this.updatedMsanConfigurationLoading = true;
      HTTP.get(
        `/components/msanData?contractId=${contractId}&uniPortId=${uniPortId}&ontDeviceId=${ontDeviceId}`
      )
        .then((response) => {
          this.updatedMsanConfiguration = response.data;
        })
        .catch((e) => e)
        .finally(() => (this.updatedMsanConfigurationLoading = false));
    },
    completeTask: function () {
      const taskId = this.data.id;
      HTTP.post(`/provisioningTask/updateMsanData/${taskId}`, {
        ontDeviceId: this.selectedONTDevice.serviceDataId,
        uniPortId: this.selectedUniPort.serviceDataId
      })
        .then((res) => {
          this.showMessage('success', 'Die Aufgabe wurde abgeschlossen');
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Der Abschluss der Aufgabe war nicht erfolgreich!'
            )
          )
        );
    },
    skipProvisioningAndCompleteTask(skipProvisioning) {
      const taskId = this.data.id;
      HTTP.post(
        `/provisioningTask/skipMsanProvisioning/${taskId}?skipMsanProvisioning=${skipProvisioning}`
      )
        .then((response) => {
          this.showMessage(
            'success',
            'Die Provisionierung wurde übersprungen und die Aufgabe abgeschlossen'
          );
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Der Abschluss der Aufgabe war nicht erfolgreich!'
            )
          )
        );
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog-buttons :first-child {
  margin-right: 5px;
}

.buttons-wrapper {
  display: grid;
  grid-template-columns: auto max-content;
  grid-gap: var(--goe-spacing-1);
}

.buttons-wrapper .v-btn {
  width: max-content;
  margin-left: auto;
  margin-right: var(--goe-spacing-0);
}

.description {
  font-size: var(--goe-fontSize-small);
  margin-top: var(--goe-spacing-0_5);
}
</style>
