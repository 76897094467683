<template>
  <div class="prozess-wrapper">
    <div class="prozess">
      <div>
        <div v-if="!claimed">
          <TaskBlocker :assignee="data.assignee" />
        </div>
        <div v-if="!isLoading">
          <div class="group">
            <div>Allgemeine Informationen</div>
            <div>
              <div class="input-row">
                <div>
                  <div class="label">
                    Fehlermeldung
                  </div>
                  <div>
                    {{ errorMessage }}
                  </div>
                </div>
              </div>
              <div class="input-row">
                <div>
                  <div class="label">
                    Vertragsnummer
                  </div>
                  <div>
                    {{ contractId }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CustomerDataEdit
            v-if="customerData !== null"
            :data="customerData"
            title="Kundendaten"
            :disabled="!claimed"
            @onDataChange="customerData = $event"
            @invalid="inputsInvalid = $event"
          />
        </div>
      </div>
      <div v-if="!isLoading" class="buttons-wrapper">
        <v-btn
          :disabled="isLoading || !claimed || inputsInvalid"
          color="primary"
          large
          @click="completeTask()"
        >
          Aufgabe abschließen
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import NotificationObject from '@/main/NotificationObject.js';
import CustomerDataEdit from '@/components/CustomerDataEdit.vue';
import TaskBlocker from '@/task/TaskBlocker.vue';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';

import { HTTP } from '@/main/httpClient.js';

export default {
  name: 'ActionEditCustomerData',
  components: {
    CustomerDataEdit,
    TaskBlocker
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    customerData: null,
    errorMessage: null,
    contractId: null,
    taskDataLoading: true,
    inputsInvalid: false
  }),
  computed: {
    username() {
      return this.$store.getters.getUsername;
    },
    claimed() {
      return this.data.assignee === this.username;
    },
    isLoading() {
      return this.taskDataLoading;
    }
  },
  mounted: function () {
    this.loadTaskData(this.data.id);
  },
  methods: {
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    loadTaskData: function (id) {
      this.taskDataLoading = true;
      HTTP.get(`/task/${id}/variables`)
        .then((resp) => {
          // Prefer local customer data to process customer data
          if (resp.data.customerData !== undefined) {
            this.customerData = resp.data.customerData.value;
          } else {
            this.customerData = resp.data.customer.value;
          }
          this.errorMessage = resp.data.error_msg.value;
          this.contractId = resp.data.contractId.value;
        })
        .catch((e) => {
          this.showMessage(
            'error',
            'Die Kundendaten konnten nicht geladen werden!'
          );
        })
        .finally(() => {
          this.taskDataLoading = false;
        });
    },
    completeTask: function () {
      HTTP.post(`/task/${this.data.id}/complete`, {
        variables: {
          customer: {
            type: 'Object',
            value: JSON.stringify(this.customerData),
            valueInfo: {
              objectTypeName: 'de.goetel.spot.porting.entity.CustomerDto',
              serializationDataFormat: 'application/json'
            }
          }
        }
      })
        .then((resp) => {
          this.showMessage(
            'success',
            'Die Aufgabe wurde erfolgreich abgeschlossen.'
          );
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(err.response.data, 'Die Aufgabe konnte nicht erfolgreich abgeschlossen werden.')
          )
        );
    }
  }
};
</script>
<style scoped>
.buttons-wrapper {
  display: grid;
  grid-template-columns: auto max-content;
  grid-gap: var(--goe-spacing-1);
}

.buttons-wrapper .v-btn {
  width: max-content;
  margin-left: auto;
  margin-right: var(--goe-spacing-0);
}

.group > div:nth-child(2) > div {
  margin-bottom: var(--goe-spacing-2);
}

.group > div:nth-child(2) > div:last-child {
  margin-bottom: var(--goe-spacing-0);
}

.input-wrapper {
  margin-bottom: var(--goe-spacing-2);
}
</style>
