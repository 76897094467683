<template>
  <div class="prozess-wrapper">
    <div class="prozess">
      <div>
        <div v-if="!claimed">
          <TaskBlocker :assignee="data.assignee" />
        </div>
        <div v-if="!isLoading">
          <CustomerDetails
            v-if="customerAddress !== null"
            :data="customerAddress"
            parseSalutation
            title="Kundeninformationen"
          />
          <div v-if="contractId">
            <DisplayContainer label="Produktinformationen">
              <AllOrderedProductsTable :allProducts="contractProducts" />
            </DisplayContainer>
            <DisplayContainer :label="'Memo'">
              <v-textarea
                ref="memo"
                v-model="memo"
                class="input-field"
                auto-grow
                clearable
                clear-icon="mdi-delete"
                name="memo-input"
                placeholder="Memo"
                :value="memo"
                :disabled="!claimed"
              />
            </DisplayContainer>
          </div>
        </div>
        <div v-else>
          <Loader />
        </div>
      </div>
      <div v-if="!isLoading" class="buttons-wrapper">
        <v-btn
          color="primary"
          large
          :disabled="isDeadlineAlreadySet || buttonsDisabled || !claimed"
          @click="setDeadLine()"
        >
          {{
            isDeadlineAlreadySet
              ? `${
                deadlineDate
                  ? 'Aktivierungsfrist: ' + deadlineDate
                  : 'Aktivierungsfrist gesetzt'
              }`
              : 'Aktivierungsfrist setzen'
          }}
        </v-btn>
        <v-btn
          color="primary"
          large
          :disabled="buttonsDisabled || !claimed"
          @click="completeTask(true)"
        >
          ONT Onlineprüfung Überspringen
        </v-btn>
        <v-btn
          color="secondary"
          large
          :disabled="buttonsDisabled || !claimed"
          @click="saveTask()"
        >
          Memo Speichern
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationObject from '@/main/NotificationObject.js';
import DatePicker from '@/components/elements/DatePicker.vue';
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import CustomerDetails from '@/components/CustomerDetails.vue';
import Loader from '@/components/elements/Loader.vue';
import AllOrderedProductsTable from '@/components/AllOrderedProductsTable.vue';
import TextLink from '@/components/elements/TextLink.vue';
import TaskBlocker from '../TaskBlocker.vue';
import { HTTP } from '@/main/httpClient.js';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';
import ErrorsDisplay from '@/components/elements/ErrorDisplay.vue';
import TimeUtility from '@/util/TimeUtility.js';

export default {
  name: 'ActionManualOntOnlineCheckNew',
  components: {
    TaskBlocker,
    Loader,
    CustomerDetails,
    DatePicker,
    NotificationObject,
    DisplayContainer,
    AllOrderedProductsTable,
    TextLink,
    ErrorMessageBuilder,
    ErrorsDisplay
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      isLoading: false,
      customerAddress: null,
      contractId: null,
      customerId: null,
      memo: null,
      contractProducts: null,
      isDeadlineAlreadySet: false,
      deadline: null
    };
  },
  computed: {
    username() {
      return this.$store.getters.getUsername;
    },
    claimed() {
      return this.data.assignee === this.username;
    },
    buttonsDisabled() {
      return this.isLoading || !this.inputIsValid(this.memo);
    }
  },
  mounted: function () {
    this.loadTaskData(this.data.id);
    this.checkDeadlineAlreadySet();
  },
  methods: {
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    updatePlannedActivationDate: function (newDate) {
      this.plannedActivationDateGUI = newDate;
      this.$emit('onDataChange', this.plannedActivationDateGUI);
    },
    loadTaskData: function (id) {
      this.isLoading = true;
      HTTP.get(`/task/${id}/variables`)
        .then((resp) => {
          // Prefer local customer data to process customer data
          this.customerId = resp.data.customerId.value;
          this.contractId = resp.data.contractId.value;
          this.loadContractProducts(this.contractId);
          if (resp.data.memo !== undefined) {
            this.memo = resp.data.memo.value;
          }

          return resp;
        })
        .then((response) => {
          return this.fetchAddressInfo(this.contractId);
        })
        .catch((e) => {
          console.log('e', e);
          this.showMessage(
            'error',
            'Die Kundendaten konnten nicht geladen werden!'
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    fetchAddressInfo(contractId) {
      return HTTP.get(`/customer/contactAddress/${contractId}`)
        .then((res) => {
          this.customerAddress = { ...res.data, customerId: this.customerId };
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Kundendaten konnten nicht geladen werden!'
            )
          )
        );
    },
    loadContractProducts: function (contractId) {
      HTTP.get(`/products/contract/all/${contractId}`)
        .then((resp) => {
          this.contractProducts = resp.data;
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Kundendaten konnten nicht geladen werden!'
            )
          )
        )
        .finally(() => {
          this.productDataLoading = false;
        });
    },
    saveTask: function () {
      HTTP.post(`/task/${this.data.id}/localVariables`, {
        modifications: {
          memo: {
            value: this.memo,
            type: 'String'
          }
        }
      });
    },
    completeTask: function (skipOnlineCheck = false) {
      HTTP.post(`/engine/default/task/${this.data.id}/complete`, {
        variables: {
          skipOntOnlineCheck: {
            type: 'Boolean',
            value: skipOnlineCheck
          },
          skipOntOnlineCheckFrom: {
            type: 'String',
            value: this.$store.getters.getUsername
          }
        }
      })
        .then((resp) => {
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch(() =>
          this.showMessage(
            'error',
            'Die Aufgabe konnte nicht erfolgreich abgeschlossen werden!'
          )
        );
    },
    setDeadLine: function () {
      HTTP.post('message', {
        processInstanceId: `${this.data.processInstanceId}`,
        messageName: 'Message_OntOnlineCheckSetActivationDeadline',
        processVariables: {
          ontOnlineCheckSetActivationDeadlineFrom: {
            type: 'String',
            value: this.$store.getters.getUsername
          }
        }
      })
        .then((resp) => {
          this.showMessage('success', 'Frist wurde gesetzt');
        })
        .catch(() =>
          this.showMessage(
            'error',
            'Die Aufgabe konnte nicht erfolgreich abgeschlossen werden!'
          )
        )
        .finally(() => {
          this.checkDeadlineAlreadySet();
        });
    },
    checkDeadlineAlreadySet: function () {
      HTTP.get(
        `process-instance/${this.data.processInstanceId}/activity-instances`
      ).then((resp) => {
        console.log(resp.data);
        const activationDeadlineSubprocess =
          resp.data.childActivityInstances[0].childActivityInstances.find(
            (e) => e.activityId === 'ONTOnlineCheckActivationDeadlineSubprocess'
          );
        if (activationDeadlineSubprocess) {
          this.isDeadlineAlreadySet = true;
          this.retrieveDeadline(
            activationDeadlineSubprocess.childActivityInstances[0]
              .executionIds[0]
          );
        }
      });
    },
    retrieveDeadline: function (executionId) {
      console.log(executionId);
      HTTP.get(`job?executionId=${executionId}`).then((resp) => {
        console.log(resp.data);
        this.deadlineDate = TimeUtility.formatDate(resp.data[0].dueDate);
      });
    },
    inputIsValid: function (memoInput) {
      return memoInput == null || memoInput.length <= 60;
    }
  }
};
</script>

<style scoped>
.dialog-buttons .v-btn {
  margin-right: 5px;
}

.buttons-wrapper .v-btn {
  width: max-content;
  margin-left: auto;
  margin-right: var(--goe-spacing-1);
  float: right;
}

.loader {
  margin: auto;
}

.group > div:nth-child(2) > div {
  margin-bottom: var(--goe-spacing-2);
}

.align-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.group > div:nth-child(2) > div:last-child {
  margin-bottom: var(--goe-spacing-0);
}

.input-wrapper {
  margin-bottom: var(--goe-spacing-2);
}
</style>
