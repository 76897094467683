var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"prozess-wrapper"},[_c('div',{staticClass:"prozess"},[_c('div',[(!_vm.claimed)?_c('div',[_c('TaskBlocker',{attrs:{"assignee":_vm.data.assignee}})],1):_vm._e(),(!_vm.taskVariablesLoading)?_c('div',[(_vm.customerData !== null)?_c('CustomerDetails',{attrs:{"data":_vm.customerData,"title":"Kundeninformationen"}}):_vm._e(),(_vm.portingData !== null)?_c('PortingInformation',{attrs:{"wbciProcessId":_vm.currentWbciProcessId,"currentProvider":_vm.currentCarrier,"portingData":_vm.portingData}}):_vm._e(),_c('div',{staticClass:"number-group"},[_c('div',[_vm._v("Rufnummern")]),_c('div',{staticClass:"input-row"},[_c('div',[_c('div',{staticClass:"label"},[_vm._v(" Nicht automatisch portierbar ")]),(
                  _vm.portingData.phoneNumbersNotFromEmittingCarrier != null &&
                    _vm.portingData.phoneNumbersNotFromEmittingCarrier != []
                )?_c('div',_vm._l((_vm.portingData.phoneNumbersNotFromEmittingCarrier),function(number){return _c('div',{key:("" + (number.areaCode) + (number.numberPart))},[(number.carrier.routingId != null)?_c('div',[_vm._v(" "+_vm._s(("+" + (number.countryCode) + " " + (number.areaCode) + " " + (number.numberPart) + " (" + (number.carrier.routingId) + ")"))+" ")]):_c('div',[_vm._v(" "+_vm._s(("+" + (number.countryCode) + " " + (number.areaCode) + " " + (number.numberPart) + " (Unbekannter EKP)"))+" ")])])}),0):_c('div',[_vm._v(" - ")])]),_c('div',[_c('div',{staticClass:"label"},[_vm._v(" Automatisch portierbar ")]),(
                  _vm.portingData.phoneNumbersFromWbciProcess != null &&
                    _vm.portingData.phoneNumbersFromWbciProcess != []
                )?_c('div',_vm._l((_vm.portingData.phoneNumbersFromWbciProcess),function(number){return _c('div',{key:("" + (number.areaCode) + (number.numberPart))},[_vm._v(" "+_vm._s(("+" + (number.countryCode) + " " + (number.areaCode) + " " + (number.numberPart)))+" ")])}),0):_c('div',[_vm._v(" - ")])])])]),_c('div',{staticClass:"group"},[_c('div',[_vm._v("Notizen")]),_c('div',[_c('v-textarea',{attrs:{"auto-grow":"","clearable":"","clear-icon":"mdi-delete","counter":"70","rules":[
                function (v) { return v == null ||
                  v.length <= 70 ||
                  'Die Notiz darf maximal 70 Zeichen lang sein!'; }
              ],"name":"memo-input","placeholder":"Notiz eingeben...","value":_vm.memo,"disabled":!_vm.claimed},model:{value:(_vm.memo),callback:function ($$v) {_vm.memo=$$v},expression:"memo"}})],1)])],1):_c('div',[_c('Loader')],1)]),(!_vm.taskVariablesLoading)?_c('div',{staticClass:"buttons-wrapper"},[_c('v-btn',{attrs:{"disabled":_vm.completeButtonDisabled || !_vm.claimed,"color":"secondary","large":""},on:{"click":function($event){return _vm.saveTask()}}},[_vm._v(" Speichern ")]),_c('v-btn',{attrs:{"color":"primary","large":"","disabled":_vm.completeButtonDisabled || !_vm.claimed},on:{"click":function($event){return _vm.completeTask()}}},[_vm._v(" Aufgabe abschließen ")])],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }