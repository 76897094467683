<template>
  <div class="prozess-wrapper">
    <div class="prozess">
      <div>
        <div v-if="!claimed">
          <TaskBlocker :assignee="data.assignee" />
        </div>
        <div v-if="!taskDataLoading">
          <CustomerDetails
            v-if="customerData !== null"
            :data="customerData"
            title="Kundeninformationen"
          />

          <div class="group">
            <div>Portierungsdaten</div>
            <div v-if="portingData !== null">
              <div class="input-row">
                <div>
                  <div class="label">
                    Vertragsnummer
                  </div>
                  <div>
                    {{ portingData.contractId }}
                  </div>
                </div>
                <div>
                  <div class="label">
                    Gewünschtes Portierungsdatum
                  </div>
                  <div>
                    {{
                      new Date(
                        portingData.requestedPortingDate
                      ).toLocaleDateString('de-DE', {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric'
                      })
                    }}
                  </div>
                </div>
              </div>
              <div class="input-row">
                <div>
                  <div class="label">
                    Zu portierende Rufnummern
                  </div>
                  <div>
                    <div
                      v-for="number in portingData.phoneNumbers"
                      :key="number"
                    >
                      {{ number }}
                    </div>
                  </div>
                </div>
                <div>
                  <div class="label">
                    Alle Rufnummern portieren?
                  </div>
                  <div>
                    {{ portingData.portAllPhoneNumbers ? 'Ja' : 'Nein' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="group">
            <div>Carrier-Vergleich</div>
            <div>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Quelle</th>
                      <th>Name</th>
                      <th>Portierungskennung</th>
                      <th>ITU Carrier-Code</th>
                      <th>Vertragspartner</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(carrier, i) in carriers" :key="i">
                      <td>{{ i === 0 ? 'Kundenangabe (Dimari)' : 'oCS' }}</td>
                      <td>
                        {{
                          carrier !== null && carrier.name !== null
                            ? carrier.name
                            : '-'
                        }}
                      </td>
                      <td>
                        {{
                          carrier !== null && carrier.routingId !== null
                            ? carrier.routingId
                            : '-'
                        }}
                      </td>
                      <td>
                        {{
                          carrier !== null && carrier.carrierCode !== null
                            ? carrier.carrierCode
                            : '-'
                        }}
                      </td>
                      <td>
                        <v-tooltip
                          v-if="
                            carrier !== null && carrier.contractExists !== null
                          "
                          bottom
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on">
                              {{
                                carrier.contractExists
                                  ? 'mdi-check-circle'
                                  : 'mdi-close-circle'
                              }}
                            </v-icon>
                          </template>
                          <span>
                            {{
                              carrier.contractExists
                                ? 'Vertragspartner'
                                : 'Kein Vertragspartner'
                            }}
                          </span>
                        </v-tooltip>
                        <template v-else>
                          -
                        </template>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </div>
          <CarrierSelection
            v-model="selectedCarrier"
            :disabled="!claimed"
            :errorMessage="
              selectedCarrier === null
                ? 'Es muss ein Carrier ausgewählt werden!'
                : null
            "
          />
        </div>

        <div v-else>
          <Loader />
        </div>
      </div>

      <div v-if="!taskDataLoading" class="buttons-wrapper">
        <v-btn
          :disabled="taskDataLoading || selectedCarrier === null || !claimed"
          color="primary"
          large
          @click="completeTask()"
        >
          Aufgabe abschließen
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from '@/main/httpClient.js';
import NotificationObject from '@/main/NotificationObject.js';

import CustomerDetails from '@/components/CustomerDetails.vue';
import NormalButton from '@/components/elements/NormalButton.vue';
import Loader from '@/components/elements/Loader.vue';
import TaskBlocker from '../TaskBlocker.vue';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';

import CarrierSelection from '@/components/CarrierSelectionVuetify.vue';

export default {
  name: 'ActionCarrierValidation',
  components: {
    NormalButton,
    TaskBlocker,
    CustomerDetails,
    Loader,
    CarrierSelection
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    taskDataLoading: false,
    customerData: null,
    portingData: null,
    selectedCarrier: null
  }),
  computed: {
    username() {
      return this.$store.getters.getUsername;
    },
    claimed() {
      return this.data.assignee === this.username;
    },
    carriers() {
      if (this.portingData !== null) {
        return [
          this.portingData.dimariCarrierDto,
          this.portingData.ocsCarrierDto
        ];
      } else return [];
    }
  },
  mounted: function () {
    this.loadTaskData(this.data.id);
  },
  methods: {
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    loadTaskData: function (id) {
      this.taskDataLoading = true;
      HTTP.get(`/task/${id}/variables`)
        .then((resp) => {
          this.customerData = resp.data.customer.value;
          this.portingData = resp.data.porting.value;
          this.selectedCarrier = this.portingData.ocsCarrierDto;
          // this.transformPortingData(resp.data.porting.value);
        })
        .catch((e) => {
          this.showMessage(
            'error',
            'Die Kundendaten konnten nicht geladen werden!'
          );
        })
        .finally(() => {
          this.taskDataLoading = false;
        });
    },
    completeTask: function () {
      HTTP.post(`/task/${this.data.id}/complete`, {
        variables: {
          currentProvider: {
            type: 'Object',
            value: JSON.stringify(this.selectedCarrier),
            valueInfo: {
              objectTypeName: 'de.goetel.spot.porting.entity.CarrierDto',
              serializationDataFormat: 'application/json'
            }
          }
        }
      })
        .then((resp) => {
          this.showMessage(
            'success',
            `Die Aufgabe wurde abgeschlossen und ${this.selectedCarrier.name} als Carrier festgelegt.`
          );
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(err.response.data, 'Der Abschluss der Aufgabe war nicht erfolgreich!')
          )
        );
    }
  }
};
</script>

<style scoped>
.buttons-wrapper {
  display: grid;
  grid-template-columns: auto max-content;
  grid-gap: var(--goe-spacing-1);
}

.buttons-wrapper .v-btn {
  width: max-content;
  margin-left: auto;
  margin-right: var(--goe-spacing-0);
}

.loader {
  margin: auto;
}

.description {
  font-size: var(--goe-fontSize-small);
  margin-top: var(--goe-spacing-0_5);
}

.group > div:nth-child(2) > div {
  margin-bottom: var(--goe-spacing-2);
}

.group > div:nth-child(2) > div:last-child {
  margin-bottom: var(--goe-spacing-0);
}

.label {
  width: calc(100% - 4px);
}

.buttons-wrapper .v-btn {
  width: max-content;
  margin-left: auto;
  margin-right: var(--goe-spacing-0);
}

.v-autocomplete {
  width: calc(100% - 4px);
}
</style>
