<template>
  <div class="prozess-wrapper">
    <div class="prozess">
      <div>
        <div v-if="!claimed">
          <TaskBlocker :assignee="data.assignee" />
        </div>

        <div v-if="!isLoading">
          <CustomerDetails
            v-if="customerData !== null"
            :data="customerData"
            title="Kundeninformationen"
          />

          <PortingInformation
            v-if="portingData !== null"
            :portingData="portingData"
            :currentProvider="currentProvider"
            :displayPhoneNumbers="true"
            :wbciProcessId="currentWbciId"
          />

          <div class="group">
            <div>Abbruchmeldung</div>
            <div>
              <div v-for="abbmReason in abbmReasons()" :key="abbmReason">
                {{ abbmReason }}
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <Loader />
        </div>
      </div>

      <div v-if="!isLoading" class="buttons-wrapper">
        <v-btn
          color="primary"
          large
          :disabled="validationErrors || !claimed"
          @click="completeTask(true)"
        >
          Meldung Quittieren
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from '@/main/httpClient.js';
import NotificationObject from '@/main/NotificationObject.js';

import CustomerDetails from '@/components/CustomerDetails.vue';
import PortingInformation from './PortingInformation.vue';
import PortingCancelationReasons from './PortingCancelationReasons.vue';

import NormalButton from '@/components/elements/NormalButton.vue';
import Loader from '@/components/elements/Loader.vue';
import TaskBlocker from '../TaskBlocker.vue';
import GoetelInput from '@/components/elements/GoetelInput.vue';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';

export default {
  name: 'ActionNegativeDateShiftFeedback',
  components: {
    NormalButton,
    TaskBlocker,
    CustomerDetails,
    PortingInformation,
    PortingCancelationReasons,
    Loader,
    GoetelInput
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    isLoading: false,
    portingData: null,
    customerData: null,
    cancellationRequest: null,
    feedbackForOcs: '',
    validationErrors: false,
    currentProvider: null,
    currentWbciId: null
  }),
  computed: {
    username() {
      return this.$store.getters.getUsername;
    },
    claimed() {
      return this.data.assignee === this.username;
    }
  },
  mounted: function () {
    this.loadTaskData(this.data.id);
  },
  methods: {
    validateInputs() {
      if (this.$refs && this.$refs.memo) {
        this.validationErrors = !this.$refs.memo.validate(true);
      } else {
        this.validationErrors = false;
      }
    },
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    abbmReasons() {
      let reasons = [];
      if (this.currentProvider.contractExists) {
        if (
          Object.keys(this.portingData.processRuns).includes(this.currentWbciId)
        ) {
          reasons = reasons.concat(
            this.portingData.processRuns[this.currentWbciId]
              .dateShiftAbbmReasons
          );
        }
      } else {
        reasons.push('Der aktuelle Anbieter ist kein WBCI-Vertragspartner.');
      }
      return reasons;
    },
    loadTaskData: function (id) {
      this.isLoading = true;
      HTTP.get(`/task/${id}/variables`)
        .then((resp) => {
          this.portingData = resp.data.porting.value;
          this.currentProvider = resp.data.currentProvider.value;
          this.contractId = this.portingData.contractId;
          this.customerData = resp.data.customer.value;
          this.currentWbciId = resp.data.currentWbciProcessId.value;
        })
        .catch(() => {
          this.showMessage(
            'error',
            'Die Daten konnten nicht vollständig geladen werden!'
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    completeTask: function (cancellationRequestApproved) {
      HTTP.post(
        `/porting/acceptUnsuccessfulUpdateOfPortingDate/${this.data.id}`
      )
        .then((resp) => {
          const message = 'Meldung Quittiert';
          this.showMessage('info', message);
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Der Abschluss der Aufgabe war nicht erfolgreich!'
            )
          )
        );
    }
  }
};
</script>

<style scoped>
.buttons-wrapper {
  display: grid;
  grid-template-columns: auto max-content;
  grid-gap: var(--goe-spacing-1);
}

.buttons-wrapper .v-btn {
  width: max-content;
  margin-left: auto;
  margin-right: var(--goe-spacing-0);
}

.loader {
  margin: auto;
}

.description {
  font-size: var(--goe-fontSize-small);
  margin-top: var(--goe-spacing-0_5);
}

.group > div:nth-child(2) > div {
  margin-bottom: var(--goe-spacing-2);
}

.group > div:nth-child(2) > div:last-child {
  margin-bottom: var(--goe-spacing-0);
}

.input-field {
  background: var(--goe-background-highlight);
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  width: calc(100% - 4px);
  min-height: var(--goe-spacing-3);
  -moz-padding-start: calc(10px - 3px);
  padding-left: 10px;
  padding-top: 5px;
}

option:first-child {
  display: none;
}

.headinglabel {
  color: var(--goe-fontColor-highlight);
  font-size: var(--goe-fontSize-normal);
}
</style>
