<template>
  <div class="group">
    <div>Stornierungsinformationen</div>
    <div>
      <div class="input-row">
        <div>
          <div class="label">
            WBCI-ID
          </div>
          <div class="input-row-max-content-left">
            <div>
              <TextLink linkType="ocs" :linkObject="cancellationRequest.wbciId">
                {{
                  cancellationRequest.wbciId !== null
                    ? cancellationRequest.wbciId
                    : '-'
                }}
              </TextLink>
            </div>
          </div>
        </div>
        <div>
          <div class="label">
            Datum der Stornierungsmeldung
          </div>
          <div>
            {{ cancellationRequest.created }}
          </div>
        </div>
      </div>
      <div class="row-max-content-left">
        <div>
          <div class="label">
            Grund der Stornierungsmeldung
          </div>
          <div>
            {{ cancellationRequest.reason }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextLink from '@/components/elements/TextLink.vue';

export default {
  name: 'PortingCancelationReasons',
  components: { TextLink },
  props: {
    cancellationRequest: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped>
.buttons-wrapper {
  display: grid;
  grid-template-columns: auto max-content;
  grid-gap: var(--goe-spacing-1);
}

.row-max-content-left {
  display: grid;
  grid-template-columns: auto;
  grid-gap: var(--goe-spacing-1);
}

.description {
  font-size: var(--goe-fontSize-small);
  margin-top: var(--goe-spacing-0_5);
}

.group > div:nth-child(2) > div {
  margin-bottom: var(--goe-spacing-2);
}

.group > div:nth-child(2) > div:last-child {
  margin-bottom: var(--goe-spacing-0);
}

.label {
  width: calc(100% - 4px);
}

option:first-child {
  display: none;
}
</style>
