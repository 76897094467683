<template>
  <DisplayContainer label="Portierungsdaten">
    <div v-if="portingData !== null">
      <div class="input-row">
        <DataDisplay label="Vertragsnummer">
          <div>
            {{ portingData.contractId }}
          </div>
        </DataDisplay>
        <DataDisplay label="WBCI-ID">
          <div class="input-row-max-content-left">
            <div>
              <TextLink linkType="ocs" :linkObject="wbciProcessId">
                {{ wbciProcessId !== null ? wbciProcessId : '-' }}
              </TextLink>
            </div>
          </div>
        </DataDisplay>

        <DataDisplay label="Alle Rufnummern portieren?">
          <div>
            {{ portingData.portAllPhoneNumbers ? 'Ja' : 'Nein' }}
          </div>
        </DataDisplay>
        <DataDisplay
          v-if="portingData.confirmedPortingDate != null"
          label="Bestätigtes Portierungsdatum"
        >
          <div>
            {{
              new Date(portingData.confirmedPortingDate).toLocaleDateString(
                'de-DE',
                {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric'
                }
              )
            }}
          </div>
        </DataDisplay>
        <DataDisplay v-else label="Gewünschtes Portierungsdatum">
          <div>
            {{
              new Date(portingData.requestedPortingDate).toLocaleDateString(
                'de-DE',
                {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric'
                }
              )
            }}
          </div>
        </DataDisplay>
        <DataDisplay label="Abgebender Anbieter">
          <div>
            {{
              currentProvider !== null
                ? currentProvider.name
                : portingData.ocsCarrierDto.name
            }}
          </div>
        </DataDisplay>
        <DataDisplay label="Portierungskennung abgebender Anbieter">
          <div>
            {{
              currentProvider !== null
                ? currentProvider.routingId
                : portingData.ocsCarrierDto.routingId
            }}
          </div>
        </DataDisplay>
        <DataDisplay label="ITU Code abgebender Carrier">
          <div>
            {{
              currentProvider !== null
                ? currentProvider.carrierCode
                : portingData.ocsCarrierDto.carrierCode
            }}
          </div>
        </DataDisplay>
        <DataDisplay
          v-if="displayPhoneNumbers == true"
          label="Zu portierende Rufnummern"
        >
          <div>
            <div v-for="number in portingData.phoneNumbers" :key="number">
              {{ number }}
            </div>
          </div>
        </DataDisplay>
      </div>
    </div>
    <div v-else>
      -
    </div>
  </DisplayContainer>
</template>

<script>
import TextLink from '@/components/elements/TextLink.vue';
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import DataDisplay from '@/components/elements/DataDisplay.vue';

export default {
  name: 'PortingInformation',
  components: { TextLink, DisplayContainer, DataDisplay },
  props: {
    wbciProcessId: {
      type: String,
      required: false,
      default: null
    },
    portingData: {
      type: Object,
      required: true
    },
    currentProvider: {
      type: Object,
      default: null,
      required: false
    },
    displayPhoneNumbers: {
      type: Boolean,
      default: false,
      required: false
    }
  }
};
</script>

<style scoped></style>
