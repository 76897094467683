<template>
  <div class="prozess-wrapper">
    <div class="prozess">
      <div>
        <div v-if="!claimed">
          <TaskBlocker :assignee="data.assignee" />
        </div>
        <div v-if="!isLoading">
          <div v-if="contractId">
            <DisplayContainer label="Kostenänderung wegen zu vieler Rufnummern">
              <div class="input-row">
                <DataDisplay label="Vertragsnummer">
                  <div v-if="contractId">
                    {{ contractId }}
                  </div>
                </DataDisplay>
                <DataDisplay label="Kundennummer">
                  <div v-if="customerId">
                    {{ customerId }}
                  </div>
                </DataDisplay>
              </div>
              <DisplayContainer
                v-if="contactAddress"
                label="Kontaktadresse"
                :isSubcomponent="true"
              >
                <ContactInformation :addressDto="contactAddress" />
              </DisplayContainer>
            </DisplayContainer>
            <DisplayContainer label="Produkte">
              <AllOrderedProductsTable :allProducts="allProducts" />
            </DisplayContainer>
            <DisplayContainer :label="'Rufnummern'">
              <v-simple-table>
                <template>
                  <thead>
                    <tr>
                      <th>Status</th>
                      <th>Rufnummer</th>
                      <th>Aktion</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="number in phoneNumbers"
                      :key="number.phoneNumber"
                    >
                      <td>
                        <table>
                          <tr>
                            <td>
                              <IconWithTooltip
                                v-if="number.unknown && !number.inactive"
                                tooltipTop
                                iconName="mdi-new-box"
                                tooltipText="Neu vom abgebenden EKP"
                              />
                            </td>
                            <td>
                              <IconWithTooltip
                                v-if="number.inactive"
                                tooltipTop
                                iconName="mdi-phone-off"
                                tooltipText="Telefonnummer ist deaktiviert"
                              />
                            </td>
                            <td>
                              <IconWithTooltip
                                v-if="number.nonPorting && !number.inactive"
                                tooltipTop
                                iconName="mdi-phone-remove"
                                tooltipText="Telefonnummer wird nicht portiert"
                              />
                            </td>
                            <td>
                              <IconWithTooltip
                                v-if="number.porting && !number.inactive"
                                tooltipTop
                                iconName="mdi-phone-check"
                                tooltipText="Telefonnummer wird portiert"
                              />
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td>
                        <div
                          v-if="number.inactive"
                          style="color: red; text-decoration: line-through"
                        >
                          {{ number.phoneNumber }}
                        </div>
                        <div v-else>
                          {{ number.phoneNumber }}
                        </div>
                      </td>
                      <td>
                        <div>
                          <DialogButtonWithTooltip
                            v-if="!number.inactive"
                            tooltipTop
                            iconName="mdi-phone-off"
                            tooltipText="Telefonnummer deaktivieren"
                            confirmButtonColor="var(--goe-background-danger)"
                            confirmButtonText="Deaktivieren"
                            customButtonClass="btn-remove-number"
                            :triggerButtonProps="{
                              fab: true,
                              color: 'secondary',
                              small: true
                            }"
                            :disabled="buttonsDisabled || !inputIsValid(memo)"
                            @onAction="deactivateNumber(number.phoneNumber)"
                          >
                            <template v-slot:dialogTitle>
                              Telefonnummer deaktivieren?
                            </template>
                            <template v-slot:dialogText>
                              Die Telefonnummer
                              <b>{{ number.phoneNumber }} </b> wird deaktiviert.
                              Möchten Sie fortfahren?
                            </template>
                          </DialogButtonWithTooltip>
                          <DialogButtonWithTooltip
                            v-else
                            tooltipTop
                            iconName="mdi-phone"
                            tooltipText="Telefonnummer wieder reaktivieren"
                            confirmButtonColor="var(--goe-background-danger)"
                            confirmButtonText="Reaktivieren"
                            customButtonClass="btn-add-number"
                            :triggerButtonProps="{
                              fab: true,
                              color: 'secondary',
                              small: true
                            }"
                            :disabled="buttonsDisabled || !inputIsValid(memo)"
                            @onAction="reactivateNumber(number.phoneNumber)"
                          >
                            <template v-slot:dialogTitle>
                              Telefonnummer reaktivieren?
                            </template>
                            <template v-slot:dialogText>
                              Die Telefonnummer
                              <b>{{ number.phoneNumber }} </b> wird reaktiviert.
                              Möchten Sie fortfahren?
                            </template>
                          </DialogButtonWithTooltip>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </DisplayContainer>
            <DisplayContainer :label="'Anmerkungen zu höheren Kosten'">
              <v-textarea
                ref="memo"
                v-model="memo"
                class="input-field"
                auto-grow
                clearable
                clear-icon="mdi-delete"
                name="memo-input"
                placeholder="Anmerkungen zu höheren Kosten"
                :value="memo"
                :disabled="!claimed"
              />
            </DisplayContainer>
          </div>
        </div>
        <div v-else>
          <Loader />
        </div>
      </div>
      <div v-if="!isLoading" class="buttons-wrapper">
        <v-btn
          color="primary"
          large
          :disabled="buttonsDisabled"
          @click="completeTask()"
        >
          Aufgabe abschließen
        </v-btn>
        <v-btn
          color="secondary"
          large
          :disabled="buttonsDisabled || !inputIsValid(memo)"
          @click="saveTask()"
        >
          Speichern
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationObject from '@/main/NotificationObject.js';
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import Loader from '@/components/elements/Loader.vue';
import AllOrderedProductsTable from '@/components/AllOrderedProductsTable.vue';
import TaskBlocker from '../TaskBlocker.vue';
import { HTTP } from '@/main/httpClient.js';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';
import DataDisplay from '../../components/elements/DataDisplay.vue';
import ContactInformation from '@/components/ContactInformation.vue';
import DialogButtonWithTooltip from '@/components/elements/DialogButtonWithTooltip.vue';
import IconWithTooltip from '@/components/elements/IconWithTooltip.vue';
import Logger from '@/main/Logger.js';

export default {
  components: {
    TaskBlocker,
    Loader,
    NotificationObject,
    DisplayContainer,
    AllOrderedProductsTable,
    ErrorMessageBuilder,
    DataDisplay,
    ContactInformation,
    DialogButtonWithTooltip,
    IconWithTooltip,
    Logger
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      isLoading: false,
      contractId: null,
      customerId: null,
      contactAddress: null,
      memo: null,
      phoneNumbers: [],
      porting: null,
      allProducts: null,
      subscriberDetails: null
    };
  },
  computed: {
    username() {
      return this.$store.getters.getUsername;
    },
    claimed() {
      return this.data.assignee === this.username;
    },
    buttonsDisabled() {
      return this.isLoading || !this.claimed;
    }
  },
  mounted: function () {
    this.loadTaskData(this.data.id);
  },
  methods: {
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    loadTaskData: function (id) {
      this.isLoading = true;
      HTTP.get(`/task/${id}/variables`)
        .then((resp) => {
          // Prefer local customer data to process customer data
          this.customerId = resp.data.customerId.value;
          this.contractId = resp.data.contractId.value;
          if (resp.data.memo !== undefined) {
            this.memo = resp.data.memo.value;
          }
          this.porting = resp.data.porting.value;

          // then load contactAddress
          HTTP.get(`/customer/contactAddress/${this.contractId}`).then(
            (res) => {
              this.contactAddress = { ...res.data };

              // then load products
              HTTP.get(`/products/contract/all/${this.contractId}`).then(
                (resp) => {
                  this.allProducts = resp.data;

                  // then load subscriber details
                  HTTP.get(
                    `/subscriber/detailsByContract/${this.contractId}`
                  ).then((res) => {
                    this.subscriberDetails = res.data;

                    // then adjust the phoneNumbers
                    this.adjustSubscribers();
                  });
                }
              );
            }
          );

          return resp;
        })
        .catch((e) => {
          console.log('e', e);
          this.showMessage(
            'error',
            'Die Kundendaten konnten nicht geladen werden!'
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    saveTask: function () {
      HTTP.post(`/task/${this.data.id}/localVariables`, {
        modifications: {
          memo: {
            value: this.memo,
            type: 'String'
          }
        }
      })
        .then((resp) => {
          const message = 'Das Abspeichern des Memos war erfolgreich!.';
          this.showMessage('info', message);
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Das Abspeichern des Memos war nicht erfolgreich!'
            )
          )
        );
    },
    completeTask: function () {
      HTTP.post(
        `/portingTask/closeCostsChanged/${this.data.id}`,
        {},
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
        .then((resp) => {
          const message = 'Der Abschluss der Aufgabe war erfolgreich!.';
          this.showMessage('info', message);
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Ein Fehler ist aufgetreten'
            )
          )
        );
    },
    inputIsValid: function (memoInput) {
      return memoInput == null || memoInput.length <= 5000;
    },
    deactivateNumber(phoneNumber) {
      Logger.log('ActionCheckCostChanging', 'deactivateNumber: ' + phoneNumber);
      HTTP.delete('/subscriber/number', {
        data: {
          contractId: this.contractId,
          phoneNumber: phoneNumber
        }
      })
        .then((resp) => {
          this.showMessage(
            'success',
            'Die Telefonnummer wurde erfolgreich deaktiviert.'
          );
          this.loadTaskData(this.data.id);
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Telefonnummer konnte nicht deaktiviert werden!'
            )
          )
        )
        .finally(() => {});
    },
    reactivateNumber(phoneNumber) {
      Logger.log('ActionCheckCostChanging', 'reactivateNumber: ' + phoneNumber);
      HTTP.post('/subscriber/reactivate', {
        contractId: this.contractId,
        phoneNumber: phoneNumber
      })
        .then((resp) => {
          this.showMessage(
            'success',
            'Die Telefonnummer wurde erfolgreich reaktiviert.'
          );
          this.loadTaskData(this.data.id);
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Telefonnummer konnte nicht reaktiviert werden!'
            )
          )
        )
        .finally(() => {});
    },
    adjustSubscribers: function () {
      Logger.log(
        'ActionCheckCostChanging',
        'SubscriberDetails',
        this.subscriberDetails
      );
      Logger.log('ActionCheckCostChanging', 'Porting', this.porting);

      this.phoneNumbers = [];

      this.porting.phoneNumbers.forEach((phoneNumber) => {
        this.addPhoneNumberToPhoneNumbers(phoneNumber, false, false, true);
      });

      this.porting.nonPortingPhoneNumbers.forEach((phoneNumber) => {
        this.addPhoneNumberToPhoneNumbers(phoneNumber, false, true, false);
      });

      this.porting.phoneNumbersDimariUnkown.forEach((phoneNumber) => {
        const concatenated =
          '0' + phoneNumber.areaCode + phoneNumber.numberPart;
        this.addPhoneNumberToPhoneNumbers(concatenated, true, false, false);
      });

      this.phoneNumbers.forEach((phoneNumber) => {
        Logger.log(
          'ActionCheckCostChanging',
          'phoneNumber: ' +
            phoneNumber.phoneNumber +
            ' unknown: ' +
            phoneNumber.unknown +
            ' inactive: ' +
            phoneNumber.inactive +
            ' nonPorting: ' +
            phoneNumber.nonPorting
        );
      });
    },
    addPhoneNumberToPhoneNumbers: function (
      phoneNumber,
      unknown,
      nonPorting,
      porting
    ) {
      var subscriber =
        this.getSubscriberDetailFromSubscriberDetailsList(phoneNumber);
      Logger.log('ActionCheckCostChanging', subscriber);

      if (subscriber != null) {
        this.phoneNumbers.push({
          phoneNumber: phoneNumber,
          inactive: this.isSubscriberInactive(subscriber),
          unknown: unknown,
          nonPorting: nonPorting,
          porting: porting
        });
      }
    },
    getSubscriberDetailFromSubscriberDetailsList: function (phoneNumber) {
      var subscriberDetail = null;
      this.subscriberDetails.forEach((subscriberDetailListElement) => {
        if (phoneNumber === subscriberDetailListElement.subscriberId) {
          Logger.log(
            'ActionCheckCostChanging',
            'Found matching phone number: ' + phoneNumber
          );
          subscriberDetail = subscriberDetailListElement;
        }
      });

      return subscriberDetail;
    },
    isSubscriberInactive: function (subscriber) {
      // a date is inactive if the dateInactive field is not null and equal or before today
      if (subscriber.dateInactive != null) {
        if (
          new Date(Date.parse(subscriber.dateInactive)).toDateString() <=
          new Date().toDateString()
        ) {
          return true;
        }
      }
      return false;
    }
  }
};
</script>

<style scoped>
.buttons-wrapper .v-btn {
  width: max-content;
  margin-left: auto;
  margin-right: var(--goe-spacing-1);
  float: right;
}

.loader {
  margin: auto;
}

.group > div:nth-child(2) > div {
  margin-bottom: var(--goe-spacing-2);
}

.group > div:nth-child(2) > div:last-child {
  margin-bottom: var(--goe-spacing-0);
}

.input-wrapper {
  margin-bottom: var(--goe-spacing-2);
}
</style>
