<template>
  <div class="prozess-wrapper">
    <div class="prozess">
      <div>
        <div v-if="!claimed">
          <TaskBlocker :assignee="data.assignee" />
        </div>
      </div>
      <div v-if="contractId">
        <missing-porting-data-wrapper
          :taskContractId="contractId"
          :claimed="claimed"
          @taskDataValid="taskDataValid = $event"
          @humanTaskData="humanTaskData = $event"
        />
      </div>
      <div v-if="!taskDataLoading" class="buttons-wrapper">
        <v-btn
          :disabled="taskDataLoading || !claimed || !taskDataValid"
          color="primary"
          large
          @click="completeTask()"
        >
          Aufgabe abschließen
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from '@/main/httpClient.js';
import NotificationObject from '@/main/NotificationObject.js';

import Loader from '@/components/elements/Loader.vue';
import TaskBlocker from '../TaskBlocker.vue';
import ElementWrapper from '@/components/elements/ElementWrapper.vue';
import MissingPortingDataWrapper from './MissingPortingDataWrapper.vue';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';

export default {
  components: {
    Loader,
    TaskBlocker,
    ElementWrapper,
    MissingPortingDataWrapper
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    contractId: null,
    taskDataLoading: false,
    taskCompleted: false,
    taskDataValid: false,
    humanTaskData: null
  }),
  computed: {
    username() {
      return this.$store.getters.getUsername;
    },
    claimed() {
      return this.data.assignee === this.username;
    }
  },
  created: function () {
    this.loadTaskData(this.data.id);
  },
  methods: {
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },

    loadTaskData: function (id) {
      this.taskDataLoading = true;
      HTTP.get(`/task/${id}/variables`)
        .then((res) => {
          this.contractId = res.data.contractId.value;
        })
        .catch((e) => {
          this.showMessage(
            'error',
            'Die Kundendaten konnten nicht geladen werden!'
          );
        })
        .finally(() => {
          this.taskDataLoading = false;
        });
    },
    completeTask: function () {
      this.humanTaskStart = true;
      const taskId = this.data.id;
      HTTP.post(`/portingTask/enterMissingPortingInformation/${taskId}`, {
        // data
        contractId: this.contractId,
        phoneNumbers: this.humanTaskData.portingData.phoneNumbers
          .filter((number) => number.active === true)
          .map((number) => number.number),
        portAllPhoneNumbers: this.humanTaskData.portingData.portAllNumbers,
        dimariCarrierDto: this.humanTaskData.portingData.selectedCarrier,
        requestedPortingDate: this.humanTaskData.portingData.portingDate,
        portPhoneNumbersWithCancellation:
          this.humanTaskData.portingData.withCancellation
      })
        .then((res) => {
          this.showMessage('success', 'Die Aufgabe wurde abgeschlossen');
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Der Abschluss der Aufgabe war nicht erfolgreich!'
            )
          )
        );
    }
  }
};
</script>

<style scoped>
.buttons-wrapper {
  display: grid;
  grid-template-columns: auto max-content;
  grid-gap: var(--goe-spacing-1);
}

.buttons-wrapper .v-btn {
  width: max-content;
  margin-left: auto;
  margin-right: var(--goe-spacing-0);
}

.loader {
  margin: auto;
}

.description {
  font-size: var(--goe-fontSize-small);
  margin-top: var(--goe-spacing-0_5);
}

.label {
  width: calc(100% - 4px);
}

.buttons-wrapper .v-btn {
  width: max-content;
  margin-left: auto;
  margin-right: var(--goe-spacing-0);
}

.v-autocomplete {
  width: calc(100% - 4px);
}
.head-wrapper {
  color: grey;
  font-weight: 400;
}
.textfield-wrapper {
  width: 30%;
}
</style>
