<template>
  <div class="prozess-wrapper">
    <div class="prozess">
      <div>
        <div v-if="!claimed">
          <TaskBlocker :assignee="data.assignee" />
        </div>

        <div v-if="!taskVariablesLoading">
          <CustomerDetails
            v-if="customerData !== null"
            :data="customerData"
            title="Kundeninformationen"
          />

          <PortingInformation
            :wbciProcessId="currentWbciProcessId"
            :currentProvider="currentProvider"
            :portingData="portingData"
          />

          <div class="number-group">
            <div>Rufnummern mit abweichenden Anbietern</div>
            <div
              v-for="number in phoneNumberPortingInformation"
              :key="`${number.areaCode}${number.numberPart}`"
            >
              <PhoneNumberCarrierDetails :numberInfo="number" />
            </div>
          </div>
        </div>

        <div v-else>
          <Loader />
        </div>
      </div>

      <div v-if="!taskVariablesLoading" class="buttons-wrapper">
        <v-btn
          color="primary"
          large
          :disabled="!claimed"
          @click="completeTask()"
        >
          Aufgabe abschließen
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from '@/main/httpClient.js';
import NotificationObject from '@/main/NotificationObject.js';

import CustomerDetails from '@/components/CustomerDetails.vue';
import PhoneNumberCarrierDetails from '@/components/PhoneNumberCarrierDetails.vue';
import NormalButton from '@/components/elements/NormalButton.vue';
import Loader from '@/components/elements/Loader.vue';
import TaskBlocker from '../TaskBlocker.vue';
import GoetelInput from '@/components/elements/GoetelInput.vue';
import PortingInformation from './PortingInformation.vue';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';

export default {
  name: 'ActionValidationOfPhoneNumberPublication',
  components: {
    NormalButton,
    TaskBlocker,
    CustomerDetails,
    Loader,
    GoetelInput,
    PortingInformation,
    PhoneNumberCarrierDetails
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    taskVariablesLoading: true,
    portingData: null,
    customerData: null,
    currentWbciProcessId: null,
    deviatingCarrierNumbers: null,
    phoneNumberPortingInformation: null,
    currentProvider: null
  }),
  computed: {
    username() {
      return this.$store.getters.getUsername;
    },
    claimed() {
      return this.data.assignee === this.username;
    }
  },
  mounted: function () {
    this.loadTaskData(this.data.id);
  },
  methods: {
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    loadTaskData: function (id) {
      this.taskVariablesLoading = true;
      HTTP.get(`/task/${id}/variables`)
        .then((resp) => {
          this.portingData = resp.data.porting.value;
          this.currentProvider = resp.data.currentProvider.value;
          this.currentWbciProcessId = resp.data.currentWbciProcessId.value;
          this.customerData = resp.data.customer.value;
          this.phoneNumberPortingInformation =
            resp.data.phoneNumberPortingInformation.value;
        })
        .catch(() => {
          console.log('Im Catch-Block');
          this.showMessage(
            'error',
            'Die Task-Daten konnten nicht geladen werden!'
          );
        })
        .finally(() => {
          this.taskVariablesLoading = false;
        });
    },
    completeTask: function () {
      HTTP.post(
        `/portingTask/validationOfPhoneNumberPublicationResult/${this.data.id}`,
        {}
      )
        .then((resp) => {
          this.showMessage(
            'info',
            'Die Aufgabe wurde erfolgreich abgeschlossen.'
          );
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Der Abschluss der Aufgabe war nicht erfolgreich!'
            )
          )
        );
    }
  }
};
</script>

<style scoped>
.buttons-wrapper {
  display: grid;
  grid-template-columns: auto max-content;
  grid-gap: var(--goe-spacing-1);
}

.buttons-wrapper .v-btn {
  width: max-content;
  margin-left: auto;
  margin-right: var(--goe-spacing-0);
}

.loader {
  margin: auto;
}

.description {
  font-size: var(--goe-fontSize-small);
  margin-top: var(--goe-spacing-0_5);
}

.number-group {
  margin-bottom: var(--goe-spacing-2);
}

.number-group > div:nth-child(1) {
  color: var(--goe-fontColor-highlight);
}

.number-group > div:not(:first-child) {
  padding: var(--goe-spacing-2);
  background: var(--goe-background-highlight);
  font-size: var(--goe-fontSize-small);
  margin-bottom: var(--goe-spacing-1);
}

.number-group > div:not(:first-child) > div {
  margin-bottom: var(--goe-spacing-2);
}

.number-group > div:not(:first-child) > div:last-child {
  margin-bottom: var(--goe-spacing-0);
}

.group > div:nth-child(2) > div {
  margin-bottom: var(--goe-spacing-2);
}

.group > div:nth-child(2) > div:last-child {
  margin-bottom: var(--goe-spacing-0);
}

.label {
  width: calc(100% - 4px);
}

.v-textarea {
  max-width: calc(100% - 12px);
}
</style>
